/**
 * @author Luka Tchigladze
 * @summary Used to set scripts using Nuxt useHead
 */

import type { MenuInterface } from "@/store/main/types/StateType";
import type { CookieInterface } from "@/store/main/types/StateType";
import { CurrentContentInterface } from "@/types/ContentType";

import { useMainStore } from "@/store/main";

export const setScripts = () => {
  const cookies = useState<CookieInterface>("cookies");

  let allowedScripts = useCookie("validCookies").value?.split(",") || [
    "necessary",
  ];

  if (!allowedScripts?.length) return;

  allowedScripts.forEach((key) => {
    const scriptHTML = atob((cookies.value?.places as any)?.[key]?.script);

    const scripts = scriptHTML.match(/<script(\n|.)*?<\/script>/g);

    const noscripts = scriptHTML.match(/<noscript(\n|.)*?<\/noscript>/g);

    if (scripts?.length) {
      for (let i = 0; i < scripts.length; i++) {
        const scriptOpenTag = scripts[i]?.match(/<(script)(?![^>]*\/>)[^>]*>/g);
        const scriptOpenTagAttrs = scriptOpenTag?.[0]?.match(
          /([\w|data-]+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g
        );

        const srcIndex = scriptOpenTagAttrs?.findIndex((x) =>
          x.includes("src=")
        ) ?? -1;

        if (srcIndex > -1) {
          const srcLink = scriptOpenTagAttrs?.[srcIndex]?.slice(5, -1);

          useHead({
            script: [
              {
                type: "text/javascript",
                src: srcLink,
                async: true,
              },
            ],
          });
        } else {
          const scriptContent = scripts[i]?.match(
            /<script[^>]*>((\n|.)*?)<\/script>/
          );

          useHead({
            script: [
              {
                type: scriptContent?.[1].includes("amo")
                  ? "text/javascript"
                  : "text/javascript",
                children: scriptContent?.[1],
              },
            ],
          });
        }
      }
    }

    if (noscripts?.length) {
      for (let i = 0; i < noscripts.length; i++) {
        const scriptContent = noscripts[i]?.match(
          /<noscript[^>]*>((\n|.)*?)<\/noscript>/
        );

        useHead({
          noscript: [
            {
              innerHTML: scriptContent?.[1],
              tagPosition: "bodyClose",
            },
          ],
        });
      }
    }
  });
};

export const setMenuScripts = () => {
  const selectedMenuState = useState<MenuInterface | undefined>("selectedMenu");

  const scriptHTML = atob(
    selectedMenuState.value?.seo.scripts
      ? selectedMenuState.value?.seo?.scripts?.toString()
      : ""
  );

  const scripts = scriptHTML.match(/<script(\n|.)*?<\/script>/g);

  if (scripts?.length) {
    for (let i = 0; i < scripts.length; i++) {
      const scriptOpenTag = scripts[i]?.match(/<(script)(?![^>]*\/>)[^>]*>/g);
      const scriptOpenTagAttrs = scriptOpenTag?.[0]?.match(
        /([\w|data-]+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g
      );

      const srcIndex = scriptOpenTagAttrs?.findIndex((x) => x.includes("src="));

      const typeIndex = scriptOpenTagAttrs?.findIndex((x) =>
        x.includes("type=")
      );

      const type = scriptOpenTagAttrs?.[Number(typeIndex)]?.slice(6, -1);

      if (srcIndex && srcIndex > -1) {
        const srcLink = scriptOpenTagAttrs?.[srcIndex]?.slice(5, -1);

        useHead({
          script: [
            {
              type: type || "text/javascript",
              src: srcLink,
              async: true,
            },
          ],
        });
      } else {
        const scriptContent = scripts[i]?.match(
          /<script[^>]*>((\n|.)*?)<\/script>/
        );

        useHead({
          script: [
            {
              type: type || "text/javascript",
              children: scriptContent?.[1],
            },
          ],
        });
      }
    }
  }
};

export const setSingleViewScript = () => {
  const currentContentState =
    useState<CurrentContentInterface>("currentContent");
  
  const mainStore = useMainStore();

  const localeScript = (
    currentContentState.value?.primary?.data?.list?.[0] as any
  )?.[`seo_script_${mainStore.lang}`];

  if (!localeScript) return
  
  const scriptHTML = atob(localeScript || "");

  const scripts = scriptHTML.match(/<script(\n|.)*?<\/script>/g);

  if (scripts?.length) {
    for (let i = 0; i < scripts.length; i++) {
      const scriptOpenTag = scripts[i]?.match(/<(script)(?![^>]*\/>)[^>]*>/g);
      const scriptOpenTagAttrs = scriptOpenTag?.[0]?.match(
        /([\w|data-]+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g
      );

      const srcIndex = scriptOpenTagAttrs?.findIndex((x) => x.includes("src="));

      const typeIndex = scriptOpenTagAttrs?.findIndex((x) =>
        x.includes("type=")
      );

      const type = scriptOpenTagAttrs?.[Number(typeIndex)]?.slice(6, -1);

      if (srcIndex && srcIndex > -1) {
        const srcLink = scriptOpenTagAttrs?.[srcIndex]?.slice(5, -1);

        useHead({
          script: [
            {
              type: type || "text/javascript",
              src: srcLink,
              async: true,
            },
          ],
        });
      } else {
        const scriptContent = scripts[i]?.match(
          /<script[^>]*>((\n|.)*?)<\/script>/
        );

        useHead({
          script: [
            {
              type: type || "text/javascript",
              children: scriptContent?.[1],
            },
          ],
        });
      }
    }
  }
};
