<script lang="ts" setup>
/**
 * @author Luka Tchigladze
 */

/** IMPORTS */
import type { Ref, ComputedRef } from "vue";

// import { useRoute } from "vue-router";
import { useBookingStore } from "@/store/booking";

import { useMainStore } from "@/store/main/index";
import { buildLinkObjFromMenu } from "@/composables/utils/router";

import { MenuInterface } from "@/store/main/types/StateType";

import Button from "@/components/regularComponents/UIComponents/Button.vue";
import LanguageDropdown from "@/components/regularComponents/UIComponents/Dropdowns/LanguageDropdown.vue";
import LogoAnimation from "./parts/LogoAnimation.vue";
import LogoTextAnimation from "./parts/LogoTextAnimation.vue";

import { reqAxios } from "@/composables/core/func";
import { sendEvent } from "@/composables/utils/sendEvent";

import type { IndxInterface } from "@/store/main/types/StateType";

const Link = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Link.vue")
);

const BurgerMenu = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/BurgerMenu.vue")
);
const Burger = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/SVG/actions/Burger.vue")
);
const VideoCallModal = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Modals/VideoCallModal.vue")
);
const BaseModal = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Modals/BaseModal.vue")
);
const CalendarModal = defineAsyncComponent(
  () =>
    import(
      "@/components/regularComponents/UIComponents/Modals/CalendarModal/CalendarModal.vue"
    )
);
const ResponseModal = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Modals/ResponseModal.vue")
);

const props = defineProps<{
  isMain?: boolean;
  isVertical?: boolean;
}>();

// ROUTER
const route = useRoute();
const router = useRouter();
const i18n = useI18n();

// STORE
const mainStore = useMainStore();
const bookingStore = useBookingStore();

const menuOpen: Ref<boolean> = ref(false);

// const areLogosReady: Ref<boolean> = ref(false);

const isModalOpen: Ref<boolean> = ref(false);
const isRequestFinished: Ref<boolean> = ref(false);

const isSecondModalOpen: Ref<boolean> = ref(false);

const isResponseOpen: Ref<boolean> = ref(false);

const isSuccessful: Ref<boolean> = ref(false);

const logoVisible: Ref<boolean> = ref(false);

// COMPUTED
const indx = useState<IndxInterface>("data");

const routeFullPath = computed(() => route.fullPath);

const projectMenu: ComputedRef<MenuInterface | undefined> = computed(() => {
  if (indx.value?.menus) {
    return indx.value?.menus?.find((m) => m?.url_slug === "projects");
  }
});

// METHODS
// const handleHomeRoute = () => {
//   if (window === undefined) return;
//   const url = useRequestURL();
//   const newUrl = url?.origin + "/" + i18n.locale.value;
//   window.location.href = newUrl;
// };
const openModal = () => {
  isModalOpen.value = true;

  // if (!route.params?.slugs?.length) {
  //   sendEvent("Instant video call - form open");
  // }

  sendEvent("form-call", { formType: "Instant video call" });
};

const closeModals = () => {
  isModalOpen.value = false;
  isSecondModalOpen.value = true;
};

const closeModalsReverse = () => {
  isModalOpen.value = true;
  isSecondModalOpen.value = false;
};

// this will be a request
const closeModalsAgain = async () => {
  isSecondModalOpen.value = false;
  isResponseOpen.value = true;

  const params = {
    date: bookingStore.getFormatForForm,
    time: bookingStore.time,
    dial: bookingStore.dial,
    timezone: bookingStore.timezone?.title,
    email: bookingStore.email,
    name: bookingStore.name,
  };

  isRequestFinished.value = false;
  const response = await reqAxios("main/saveSubmitedForm", {
    formType: "bookedCalls",
    ...params,
  });
  isRequestFinished.value = true;
  isSuccessful.value = response?.data?.[0] === "success";

  // if (!route.params?.slugs?.length) {
  //   sendEvent("Instant video call - submission");
  // }

  if (isSuccessful.value) {
    sendEvent("form-submission", { formType: "Instant video call" });
  }

  bookingStore.$reset();
};

router.afterEach(() => (menuOpen.value = false));
// onMounted(() => {
//   setTimeout(() => {
//     areLogosReady.value = true;
//   }, 1000);
// });
// WATCHERS
watch(routeFullPath, () => {
  if (route.params?.slugs?.length > 1) {
    logoVisible.value = false;
    setTimeout(() => {
      logoVisible.value = true;
    }, 1000);
  }
  // menuOpen.value = false;
}, {
  immediate: true,
});
</script>

<template>
  <header
    class="header bg-white w-full pos-rel"
    :class="{ 'header--is-vertical': isVertical }"
  >
    <div
      class="container-full d-flex j-btw pos-rel"
      :class="{ 'header--active': menuOpen }"
    >
      <div
        class="logo-gumbati d-flex al-center"
        :class="{ 'logo-gumbati--vertical': isVertical }"
      >
        <a
          v-if="isVertical"
          class="header__logo-wrapper header__logo-wrapper--vertical d-flex dir-col pointer"
          :href="'/' + i18n.locale.value"
          aria-label="Gumbari logo"
        >
          <!-- <iframe
            src="/gumbati_logo.html"
            width="56"
            height="80"
            frameborder="0"
        ></iframe>
          <iframe
            src="/gumbati_group_logo.html"
            width="55"
            height="60"
            frameborder="0"
            style="margin-top: -1px"
          ></iframe> -->
          <!-- <img
            src="@/assets/images/logo/verticalLogo.svg"
            width="73"
            height="120"
            alt="Logo"
          /> -->

          <LogoAnimation v-show="logoVisible" :delay="1500" :style="{ width: '56px', height: '80px' }" />
          <LogoTextAnimation v-show="logoVisible" :delay="1500" :style="{ width: '55px', height: '24px' }" />
        </a>

        <a
          v-else
          class="header__logo-wrapper d-flex al-center pointer"
          :href="'/' + i18n.locale.value"
          aria-label="Gumbari logo"
        >
          <!-- <iframe
            src="/gumbati_logo.html"
            width="36"
            height="60"
            frameborder="0"
          ></iframe>
          <iframe
            src="/gumbati_group_logo.html"
            width="154"
            height="60"
            frameborder="0"
            style="margin-left: 15px"
          ></iframe> -->
          <!-- <img src="@/assets/images/logo/logo.svg" width="242" height="58" alt="Logo" /> -->

          <LogoAnimation :style="{ width: '36px', height: '60px' }" />
          <LogoTextAnimation :style="{ width: '154px', height: '60px', marginLeft: '15px' }" />
        </a>
      </div>

      <div class="header__content d-flex al-center j-end">
        <Button
          icon="video"
          :btnType="5"
          btnText="instant video call"
          @click="openModal"
        />

        <LanguageDropdown />
        
        <Link
          v-if="projectMenu"
          :label="projectMenu?.titles?.[mainStore.lang]?.title"
          class="header__link"
          :link="buildLinkObjFromMenu(projectMenu, '?page=1&type=current')"
        >
          <Button :btnText="projectMenu?.titles?.[mainStore.lang]?.title" />
        </Link>

        <Burger
          class="header__burger pos-rel"
          :class="{ 'header__burger--active': menuOpen }"
          @click="menuOpen = true"
        />
      </div>

      <LanguageDropdown v-if="isVertical" />
    </div>

    <Teleport to="body">
      <Transition name="fade-out">
        <BurgerMenu v-if="menuOpen" @close="menuOpen = false" />
      </Transition>
    </Teleport>

    <Teleport to="body">
      <Transition name="fade-out">
        <BaseModal
          v-if="isModalOpen"
          isCalendar
          @close="isModalOpen = false"
          maxWidth="817px"
          title="Schedule Meeting"
        >
          <CalendarModal @clicked="closeModals" />
        </BaseModal>
      </Transition>
    </Teleport>

    <Teleport to="body">
      <Transition name="fade-out">
        <BaseModal
          v-if="isSecondModalOpen"
          title="Schedule Meeting"
          :hasBackButton="true"
          @close="isSecondModalOpen = false"
          @back="closeModalsReverse"
        >
          <VideoCallModal @clicked="closeModalsAgain" />
        </BaseModal>
      </Transition>
    </Teleport>

    <Teleport to="body">
      <Transition name="fade-out">
        <BaseModal
          v-if="isResponseOpen && isRequestFinished"
          @close="isResponseOpen = false"
        >
          <ResponseModal :success="isSuccessful" @close="isResponseOpen = false" />
        </BaseModal>
      </Transition>
    </Teleport>
  </header>
</template>

<style lang="scss">
.header {
  padding: 41px 0;
  border-bottom: 1px solid $brightGray;

  z-index: 7;

  iframe {
    pointer-events: none;
  }

  @include easeInOut(500ms, transform);

  &--is-vertical {
    position: relative;
    transition: none;
    z-index: 100;
  }

  @include maxq(desktop-2k) {
    padding: 28px 0;
  }

  @include maxq(desktop-lg) {
    padding: 21px 0;
  }

  @include maxq(tablet) {
    padding: 24px 0;
  }

  // .logo-gumbati {
  //   width: 480px;

  //   @include maxq(desktop-2k) {
  //     width: 320px;
  //   }

  //   @include maxq(desktop-lg) {
  //     width: 200px;
  //   }

  //   @include maxq(tablet) {
  //     width: 165px;
  //   }R

  //   img {
  //     width: 100%;
  //     height: auto;
  //   }
  // }

  .container-full {
    & > span {
      display: none;
    }
  }

  &__content {
    min-width: 600px;

    @include maxq(mobile) {
      min-width: auto;
    }

    .header__link {
      margin-right: 88px;

      @include maxq(desktop-2k) {
        margin-right: 55px;
      }

      @include maxq(desktop-lg) {
        margin-right: 40px;
      }

      @include maxq(tablet) {
        display: none;
      }
    }

    .button--5 {
      margin-right: 65px;
      min-width: 402px;

      @include maxq(desktop-2k) {
        margin-right: 38px;
        min-width: 265px;
      }

      @include maxq(desktop-lg) {
        margin-right: 24px;
        min-width: 200px;
      }

      @include maxq(desktop-sm) {
        min-width: 180px;

        .button--has-icon {
          min-width: 145px;

          .icon, span:first-child {
            min-width: 24px;
          }

          .button__content {
            min-width: 100px;
          }
        }
      }

      @include maxq(tablet) {
        display: none;
      }

      span {
        font-size: 36px;
        line-height: 44px;

        @include maxq(desktop-2k) {
          font-size: 22px;
        }

        @include maxq(desktop-lg) {
          font-size: 16px;
        }
      }
    }

    .language__dropdown {
      margin-right: 39px;

      @include maxq(desktop-2k) {
        margin-right: 22px;
      }

      @include maxq(desktop-lg) {
        margin-right: 16px;
      }

      &-wrapper {
        min-width: 200px;
        height: 100%;

        @include maxq(desktop-2k) {
          min-width: 144px;
        }
        
        @include maxq(desktop-lg) {
          min-width: 110px;
        }

        @include maxq(tablet) {
          display: none;
        }
      }
    }

    .button--1 {
      @include maxq(desktop-2k) {
        padding: 0 36px;
      }

      @include maxq(desktop-lg) {
        padding: 0 32px;
      }

      span {
        font-size: 36px;

        @include maxq(desktop-2k) {
          font-size: 24px;
        }

        @include maxq(desktop-lg) {
          font-size: 16px;
        }
      }
    }
  }

  &__burger {
    padding: 8px;
    margin-right: -8px;

    svg {
      width: 79px;
      height: 38px;

      @include maxq(desktop-2k) {
        width: 54px;
        height: 28px;
      }

      @include maxq(desktop-lg) {
        width: unset;
        height: 22px;
      }
    }

    line {
      stroke-width: 2px;
      stroke-dasharray: 40;
      stroke-linecap: round;
      @include easeInOut(350ms, all);

      &:first-child {
        stroke-dashoffset: 64;
      }

      &:nth-child(2) {
        stroke-dashoffset: 80;
      }
    }

    @include hover {
      line {
        &:first-child {
          stroke-dashoffset: 80;
        }

        &:nth-child(2) {
          stroke-dashoffset: 64;
        }
      }
    }

    &--active {
      line {
        &:first-child {
          stroke-dashoffset: 80;
        }

        &:nth-child(2) {
          stroke-dashoffset: 64;
        }
      }
    }
  }

  .slide-up-down__container {
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 3;
  }

  &__logo-wrapper {
    margin-top: 10px;
    transform-origin: center center;
    transform: scale(1.9);
    margin-left: 86px;

    @include maxq(desktop-2k) {
      transform: scale(1.6);
      margin-left: 62px;
    }

    @include maxq(desktop-lg) {
      transform: scale(1);
      margin-left: 0px;
    }

    @include maxq(mobile) {
      transform: scale(0.8);
      margin-left: -30px;
    }

    @include maxq(mobile-sm) {
      transform: scale(0.7);
      margin-left: -20px;
    }

    &--vertical {
      transform-origin: center center;
      transform: scale(1.9);
      margin-left: 46px;
      margin-top: 256px;

      @include maxq(desktop-2k) {
        margin-left: 20px;
        margin-top: 192px;
      }

      @include maxq(desktop-lg) {
        transform: scale(1.3);
        margin-left: 10px;
        margin-top: 115px;
      }

      @include maxq(mobile) {
        transform: scale(0.8);
        margin-left: -10px;
      }

      @include maxq(mobile-sm) {
        transform: scale(0.7);
        margin-left: -20px;
      }
    }
  }

  .logo-gumbati {
    // width: 284px;
    min-width: 210px;
    height: 128px;

    // &--vertical {
    // }

    @include maxq(desktop-2k) {
      height: 84px;
    }

    @include maxq(desktop-lg) {
      // width: 150px;
      height: 64px;
    }

    @include maxq(tablet) {
      // width: 124px;
      height: 40px;
    }
  }

  &--fixed {
    top: 0;
    left: 0;
    transform: translateY(-100%);
    padding: 26px 0;
    @include shadow;

    @include maxq(desktop-2k) {
      padding: 15px 0;
    }

    @include maxq(desktop-lg) {
      padding: 7px 0;
    }

    @include maxq(tablet) {
      padding: 11px 0;
    }

    .button--1 {
      height: 84px;
      padding: 0 48px;

      @include maxq(desktop-2k) {
        height: 64px;
        padding: 0 24px;
      }

      @include maxq(desktop-lg) {
        height: 48px;
      }
    }

    .header__burger {
      svg {
        @include maxq(tablet) {
          height: 18px;
        }
      }
    }
  }

  &--expanded {
    transform: translateY(0%);
  }

  &--vertical {
    height: 100vh;
    border-right: 1px solid $brightGray;
    position: sticky;
    top: 0;

    @include maxq(desktop-2k) {
      padding: 64px 0 52px;
    }

    @include maxq(desktop-lg) {
      padding: 48px 0 36px;
    }

    .container-full {
      flex-direction: column;
      height: 100%;
    }

    .logo-gumbati {
      width: auto;
    }

    .header {
      &__content {
        min-width: auto;
        flex-direction: column;
        align-items: center;

        .button--5 {
          display: none;
        }

        .language__dropdown {
          display: none;
        }

        .header__link {
          display: none;
        }
      }

      &__burger {
        margin: 0;

        svg {
          transform: scaleX(-1);
        }
      }
    }

    .language__dropdown {
      margin-right: 0;
      padding: 12px 15px;

      @include maxq(desktop-2k) {
        padding: 12px 6px;
      }

      @include maxq(desktop-lg) {
        padding: 12px 10px;
      }

      &--items {
        width: 147px;

        @include maxq(desktop-2k) {
          width: 96px;
        }

        @include maxq(desktop-lg) {
          width: 74px;
        }
      }

      .drop-content {
        padding-top: 0;
        padding-bottom: 8px;
        top: unset;
        bottom: 100%;
        transform-origin: bottom right;

        &-in {
          @include maxq(desktop-lg) {
            min-width: 74px;
          }
        }
      }
    }
  }
}
</style>