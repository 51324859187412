/**
 * @author Beka Chkhaidze
 * @summary Used to work with cookies and localstorage
 */

export const setCookie = (key: string, value: string, days = 1): void => {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + days); // set expiry date to 1 year from now

    const expires = `expires=${expiryDate.toUTCString()}`;

    document.cookie = `${key}=${value};${expires};path=/`;
};

export const getCookie = (key: string): string | undefined => {
    const cookies = document?.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const [cookieKey, cookieValue] = cookie.split("=");

        if (cookieKey === key) {
            return cookieValue;
        }
    }

    return undefined;
};
