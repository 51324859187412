/**
 * @author Alexander Kenchoshvili
 */

//

import { Flat } from "@/store/building/types/StateType";
import { useMainStore } from "@/store/main/index";
import { useBuildingStore } from "@/store/building/index";

export function asignRoute(flatsWithoutRoute: Flat[], routeParams: any) {
    const buildingStore = useBuildingStore();
    flatsWithoutRoute?.forEach((flat) => {
        flat.route = !buildingStore.selectedProjectBlocks?.length
            ? `${useMainStore().lang}/${routeParams[0]}/${
                  routeParams[1]
              }/${routeParams[2]}/flat-${flat?.flat_number}`
            : `${useMainStore().lang}/${routeParams[0]}/${
                  routeParams[1]
              }/${routeParams[2]}/${routeParams[3]}/flat-${
                  flat?.flat_number
              }`;
        flat.flat_type = buildingStore?.selectedProjectTypes?.find(
            (flatType) => flat?.development_types_id === flatType?.id
        );
    });
}
