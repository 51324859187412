/**
 * @author Luka Tchigladze
 * @summary general event send function for google tag manager (gtag) & yandex
 */

// @ts-nocheck

// const yandexIds = {
//   "?thankyou": 83851522,
// };

export const sendEvent = (name: string, params: any = {}) => {
  if (typeof document === "undefined" || typeof window === "undefined") return;

  // if (window.ym && yandexIds?.[name]) {
  //   window.ym(yandexIds[name], "reachGoal", name);
  // }
  
  if (window.dataLayer) {
    console.log(name, params);

    window.dataLayer?.push({
      event: name,
      ...params
    });
    // if (params) {
    //   window.gtag("event", name, params);
    // } else {
    //   window.gtag("event", name);
    // }
  }
};
