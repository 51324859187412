<script setup lang="ts">
/**
 * @author Luka Tchigladze
 */

/** IMPORTS */
import { ref, computed, onMounted } from "vue";
import type { Ref, ComputedRef } from "vue";
import Dropdown from "@/components/regularComponents/UIComponents/Dropdown.vue";
import { IndxInterface, MenuInterface } from "@/store/main/types/StateType";
import { CurrentContentInterface, SecondaryDataInterface } from "@/types/ContentType";
import appInitHooks from "@/composables/hooks/appInitHooks";
import { useMainStore } from "@/store/main";

const route = useRoute();

interface Locale {
  title: string;
  value: string;
}

const mainStore = useMainStore();
const router = useRouter();
const i18n = useI18n();
const switchLocalePath = useSwitchLocalePath();

const dropActive: Ref<boolean> = ref(false);

const language = computed(() => i18n.locale.value);

const languages: Ref<Locale[]> = ref([
  {
    title: "GEO",
    value: "ka",
  },
  {
    title: "ENG",
    value: "en",
  },
  {
    title: "RUS",
    value: "ru",
  },
]);

// COMPUTED
const languageTitle: ComputedRef<string | undefined> = computed(
  () => languages.value.find((lng: Locale) => lng.value === language.value)?.title
);
const filteredLanguages = computed(() =>
  languages.value?.filter((lng) => lng.value !== language.value)
);
// const windowWidth: ComputedRef<number> = computed(() => mainStore.windowWidth);

// const isDesktop = computed(() => windowWidth.value > mainStore.breakpoints.tablet);

const onEvents = {
  // mouseenter() {
  //   isDesktop.value ? () => (dropActive.value = true) : null;
  // },
  // mouseleave() {
  //   isDesktop.value ? () => (dropActive.value = false) : null;
  // },
  click() {
    // if (!isDesktop.value) {
    dropActive.value = !dropActive.value;
    // }
  },
};

const handleLocaleChange = async (locale: string): Promise<void> => {
  await i18n.setLocale(locale);
  const link = switchLocalePath(locale);
  router.push(link);
  document.documentElement.setAttribute("lang", locale);
};

onMounted(() => {
  window?.addEventListener("scroll", () => {
    dropActive.value = false;
  });
});
</script>

<template>
  <div class="language__dropdown-wrapper d-flex al-center">
    <ClientOnly>
      <Dropdown
        class="language__dropdown"
        v-on="onEvents"
        :active="dropActive"
        v-click-outside="() => (dropActive = false)"
      >
        <span class="language__dropdown--value">{{ languageTitle }}</span>

        <template #body>
          <div class="language__dropdown--container d-flex dir-col">
            <div class="language__dropdown--items pos-rel">
              <div
                v-for="lang in filteredLanguages"
                class="language__dropdown--item d-flex al-center p-16-regular c-darker-blue br-4"
                :class="{
                  'language__dropdown--item-selected': language === lang.value,
                }"
                @click="handleLocaleChange(lang.value)"
              >
                {{ lang?.title }}
              </div>
            </div>
          </div>
        </template>
      </Dropdown>
    </ClientOnly>
  </div>
</template>

<style lang="scss">
.language__dropdown {
  height: 78px;
  margin-right: 42px;
  padding: 12px 19.5px;

  @include maxq(desktop-2k) {
    height: 58px;
    margin-right: 24px;
    padding: 12px 18px;
  }

  @include maxq(desktop-lg) {
    height: 48px;
    margin-right: 16px;
    padding: 12px 17.5px;
  }

  &--value {
    font-size: 34px;
    line-height: 41px;

    @include maxq(desktop-2k) {
      font-size: 22px;
      line-height: 26px;
    }

    @include maxq(desktop-lg) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .drop-icons {
    &-move {
      @include easeInOut(350ms, transform);
    }
  }

  .drop-content {
    @include maxq(mobile) {
      width: 100%;
    }

    &-in {
      min-width: 89px;
      padding: 0;
    }
  }

  &--items {
    width: 160px;

    @include maxq(desktop-2k) {
      width: 120px;
    }

    @include maxq(desktop-lg) {
      width: 89px;
    }

    @include maxq(mobile) {
      width: 100%;
    }
  }

  &--item {
    height: 78px;
    padding: 12px 16px;
    font-size: 34px;
    line-height: 41px;
    @include easeInOut(200ms, background-color);

    @include maxq(desktop-2k) {
      height: 58px;
      font-size: 22px;
      line-height: 26px;
    }

    @include maxq(desktop-lg) {
      height: 44px;
      font-size: 15px;
      line-height: 20px;
    }

    &-selected {
      background-color: $cultured;
    }

    @include hover {
      background-color: $cultured;
    }
  }

  @include hover {
    background-color: $disabled;
  }
}
</style>
