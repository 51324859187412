<script setup lang="ts">
import { CurrentContentInterface } from "@/types/ContentType";

import axios from "axios";
import resizeHandler from "@/composables/utils/resizeHandler";
import { setScripts, setMenuScripts, setSingleViewScript } from "@/composables/utils/setScripts";
import { useMainStore } from "@/store/main";
import { useBookingStore } from "@/store/booking";
import Loading from "@/components/regularComponents/UIComponents/Loading/Loading.vue";

import Header from "@/components/regularComponents/layoutComponents/header/Header.vue";
import Footer from "@/components/regularComponents/layoutComponents/footer/Footer.vue";
import ClearCache from "@/components/regularComponents/UIComponents/ClearCache.vue";

const Grid = defineAsyncComponent(
  () => import("@/components/regularComponents/layoutComponents/Grid.vue")
);
const Render = defineAsyncComponent(
  () => import("@/components/smartComponents/Render/Render.vue")
);
const Floors = defineAsyncComponent(
  () => import("@/components/regularComponents/BuildingModule/Floors.vue")
);
const Flats = defineAsyncComponent(
  () => import("@/components/regularComponents/BuildingModule/Flats.vue")
);

import { useState } from "nuxt/app";
import { IndxInterface, MenuInterface } from "@/store/main/types/StateType";
// @ts-ignore
import { RouteLocationNormalizedLoaded } from ".nuxt/vue-router";
import { useBuildingStore } from "@/store/building";
import { SecondaryDataInterface } from "@/types/ContentType";

import { getCookie, setCookie } from "@/composables/utils/storage";

const route = useRoute();
const routeFullPath = computed(() => route.fullPath);
const oldRoute: Ref<RouteLocationNormalizedLoaded | null> = ref(null);

const mainStore = useMainStore();
const buildingStore = useBuildingStore();
const bookingStore = useBookingStore();

const indx = useState<IndxInterface>("data");
const isNotMounted = useState("isNotMounted", () => {
  return (
    route.fullPath !== "/" &&
    route.fullPath !== "/ka" &&
    route.fullPath !== "/en" &&
    route.fullPath !== "/ru"
  );
});
const isLoading = useState("loading");
const selectedMenuState = useState<MenuInterface | undefined>("selectedMenu");
const currentContentStatusState = useState<string>("currentContentStatus");

const isBlockFlow = useState<boolean>("isBlockFlow");
const isFloorFlow = useState<boolean>("isFloorFlow");
const isFlatFlow = useState<boolean>("isFlatFlow");

const selectedComponentsState = useState<SecondaryDataInterface[]>("selectedComponents");
// import CookieAlert from '@/components/regularComponents/UIComponents/CookieAlert/CookieAlert.vue'

const isProjectPage = computed(() => {
  const parentMenu = indx.value?.menus?.find(
    (item) => selectedMenuState.value?.pid === item.id
  );
  return parentMenu?.url_slug === "projects";
});

const isVertical = computed(
  () =>
    (isProjectPage.value && mainStore.windowWidth > mainStore.breakpoints.tablet) ||
    (isFloorFlow.value && mainStore.windowWidth > mainStore.breakpoints.tablet) ||
    (isFlatFlow.value && mainStore.windowWidth > mainStore.breakpoints.tablet) ||
    (isBlockFlow.value && mainStore.windowWidth > mainStore.breakpoints.tablet)
);

setScripts();

onMounted(() => {
  axios.interceptors.request.use(
    (config) => {
      if (config.url?.endsWith("saveSubmitedForm")) {
        ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_referrer"].forEach((key) => {
          if (config.headers && getCookie(key)) {
            config.headers[key.replace("_", "-")] = getCookie(key) as string;
          }
        });
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // console.clear();

  setTimeout(() => {
    window.scrollTo({ top: 0 });
  }, 100);
  
  setTimeout(() => {
    isNotMounted.value = false;
    isLoading.value = false;
  }, 2000);

  resizeHandler();
  if (window !== undefined) {
    bookingStore.getCountryCodes();
  }

  ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_referrer"].forEach((key) => {
    if (route.query.hasOwnProperty(key) && getCookie(key) != route.query[key]) {
      setCookie(key, route.query[key] as string, 30);
    }
  });
});

const customKey = computed(() => {
  if (route.path === "/about" || route.path.endsWith("/contact-us")) {
    return "static";
  }
  return route.fullPath;
});

const isClearCache = computed(() => {
  return route.path?.includes("clear-cache");
});

watch(
  routeFullPath,
  async (ns, os) => {
    const currentContentState =
      useState<CurrentContentInterface>("currentContent");

    if (!currentContentState.value?.primary?.data?.list?.length) {
      setMenuScripts();
    } else {
      setSingleViewScript();
    }

    const locales = ["ka", "en", "ru"];
    if (!route.params?.slugs?.length || typeof route.params.slugs === "string") return;

    const isLocaleBugged = route.params.slugs?.some((x: string) => locales.includes(x));
    if (isLocaleBugged) route.params.slugs?.shift();
    const params = route.params.slugs;

    if (!Object.keys(params || {}).length) return;

    const ignoreNews =
      params?.[0] === "news" &&
      params?.[0] === oldRoute.value?.params?.slugs?.[0] &&
      (Boolean(route?.query?.hasOwnProperty("searchText")) ||
        Boolean(route?.query?.hasOwnProperty("year")));

    const ignoreProjects =
      params?.[0] === "projects" &&
      params?.[0] === oldRoute.value?.params?.slugs?.[0] &&
      Boolean(route?.query?.hasOwnProperty("type")) &&
      !oldRoute.value?.params?.slugs?.[1] &&
      !params?.[1] &&
      !params?.[2];

    const ignoreAbout =
      params?.[0] === "about" &&
      params?.[0] === oldRoute.value?.params?.slugs?.[0] &&
      Boolean(route?.query?.hasOwnProperty("type"));

    const langChanged = route?.name != oldRoute.value?.name;

    let ignore =
      ignoreNews || ignoreProjects || ignoreAbout || mainStore.hasIgnorableQuery;

    if (langChanged) ignore = false;

    // ignore reference
    try {
      oldRoute.value = JSON.parse(JSON.stringify(route));
    } catch (e) {}

    const isProjectPage: boolean = params?.[0] === "projects" && params?.[1]?.length > 0;
    const isBlockPage = isProjectPage && params?.[2]?.includes("block-");
    const isFloorPage =
      (isProjectPage && params?.[2]?.includes("floor-")) ||
      (isProjectPage && isBlockPage && params?.[3]?.includes("floor-"));
    const isFlatsPage = isProjectPage && isFloorPage && (params?.[3]?.includes("flat-") || params?.[4]?.includes("flat-"));

    if (isProjectPage) {
      const oldProject = os?.split("/")?.filter(x => !["en", "ka", "ru"].includes(x) && x)?.[1];
      const newProject = ns?.split("/")?.filter(x => !["en", "ka", "ru"].includes(x) && x)?.[1];

      if (newProject !== oldProject) {
        await buildingStore.getProject();
      }

      if (isBlockPage) {
        mainStore.isBlockFlow = true;
        buildingStore.setSelectedBlock(params?.[2] as string);
      } else {
        mainStore.isBlockFlow = false;
      }
    }

    if (isFloorPage) {
      await buildingStore.getFlatsByFloorNumber(
        buildingStore.selectedBlock ? (params?.[3] as string) : (params?.[2] as string)
      );
    }

    if (isFlatsPage) {
      buildingStore.setSelectedFlat(params?.[4] as string || params?.[3] as string);
    } else {
      buildingStore.selectedFlat = undefined;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>

<template>
  <!-- <DelayHydration> -->
    <ClientOnly>
      <Header
        v-if="!isVertical"
        class="header--fixed pos-fixed"
        :class="{ 'header--expanded': mainStore.headerScrolled && mainStore.isScrollingUp }"
      />
    </ClientOnly>

    <div :class="`page-${selectedMenuState?.url_slug}`">
      <NuxtLayout>
        <Grid
          v-if="!isFlatFlow && !buildingStore.selectedFlat"
          :gridType="isProjectPage ? 3 : 4"
          :style="{
            height: '100%',
          }"
        />

        <Header v-if="!isVertical" />

        <div
          class="content-wrapper"
          :class="[
            `content-wrapper--${
              isProjectPage || isFloorFlow || isFlatFlow || isBlockFlow
                ? 'project'
                : selectedMenuState?.url_slug
            }`,
            isFlatFlow || isFloorFlow ? 'content-wrapper--project-flat' : '',
          ]"
        >
          <!-- BUILDING MODULE: FLATS -->
          <template v-if="isFlatFlow && buildingStore.selectedFlat">
            <div class="content-wrapper-left" v-if="isVertical">
              <Header :isVertical="true" class="header--vertical" />
            </div>
            <Flats class="content-wrapper-right" />
          </template>

          <!-- BUILDING MODULE: FLOOR -->
          <template v-else-if="isFloorFlow && buildingStore.selectedProjectFlats?.length">
            <div class="content-wrapper-left" v-if="isVertical">
              <Header :isVertical="true" class="header--vertical" />
            </div>
            <Floors class="content-wrapper-right" />
          </template>

          <!--  BUILDING MODULE: BLOCK  -->
          <template v-else-if="isBlockFlow">
            <div class="content-wrapper-left" v-if="isVertical">
              <Header :isVertical="true" class="header--vertical" />
            </div>

            <Render
              class="content-wrapper-right"
              v-if="buildingStore?.selectedBlock?.id"
            />
          </template>

          <template
            v-else-if="
              selectedComponentsState !== null &&
              selectedComponentsState?.length &&
              !isFlatFlow &&
              !isFloorFlow
            "
          >
            <div class="content-wrapper-left" v-if="isVertical">
              <Header :isVertical="true" class="header--vertical" />
            </div>
            <div class="content-wrapper-right">
              <Teleport to="body">
                <Transition name="fade">
                  <ClearCache v-if="mainStore.showClearCacheModal" />
                </Transition>
              </Teleport>

              <NuxtPage :key="customKey" />
            </div>
          </template>

          <template
            v-else-if="
              !(selectedComponentsState !== null && selectedComponentsState?.length) &&
              currentContentStatusState === 'resolved'
            "
          >
            <div class="content-wrapper-left" v-if="isVertical">
              <Header :isVertical="true" class="header--vertical" />
            </div>
            <div class="content-wrapper-right">
              <NuxtPage v-if="isClearCache" :key="customKey" />

              <LazySmError v-else :redirect="true" />
            </div>
          </template>
        </div>

        <Footer v-if="!isFlatFlow && !isFloorFlow && !buildingStore.selectedFlat" />

        <ClientOnly>
          <Teleport to="body">
            <LazyLyFixedBookCall />
          </Teleport>
        </ClientOnly>

        <!-- <ClientOnly>
          <Teleport to="body">
            <LazyLyAmoCrm />
          </Teleport>
        </ClientOnly> -->

        <ClientOnly>
          <Teleport to="body">
            <LazyLyAmoCrmNew />
          </Teleport>
        </ClientOnly>
      </NuxtLayout>
    </div>
  <!-- </DelayHydration> -->

  <!-- <ClientOnly>
    <CookieAlert />
  </ClientOnly> -->

  <Loading v-show="(isLoading || isNotMounted) && !mainStore.showClearCacheModal" />
</template>

<style lang="scss">
.content-wrapper {
  min-height: 100vh;
  overflow-x: hidden;

  &--project {
    display: flex;
    overflow-x: unset;

    .content-wrapper-right {
      width: calc(100% - 339px);
      overflow-x: hidden;

      @include maxq(desktop-2k) {
        width: calc(100% - 226px);
      }

      @include maxq(desktop-lg) {
        width: calc(100% - 170px);
      }

      @include maxq(tablet) {
        width: 100%;
      }
    }

    .container {
      padding-left: 339px;
      padding-right: 339px;

      @include maxq(desktop-2k) {
        padding-left: 226px;
        padding-right: 226px;
      }

      @include maxq(desktop-lg) {
        padding-left: 170px;
        padding-right: 170px;
      }

      @include maxq(tablet) {
        padding-left: 104px;
        padding-right: 104px;
      }

      @include maxq(mobile) {
        padding-left: 48px;
        padding-right: 48px;
      }

      @include maxq(mobile-sm) {
        padding-left: 14px;
        padding-right: 14px;
      }
    }

    &-flat {
      @include maxq(tablet) {
        min-height: calc(100vh - 89px);
        height: calc(100vh - 89px);
      }

      @media (max-width: 1247px) and (min-width: 984px) and (max-height: 700px) {
        min-height: calc(100vh + 300px);
        height: calc(100vh + 300px);
      }

      @media (max-width: 983px) and (max-height: 500px) {
        min-height: calc(100vh + 400px);
        height: calc(100vh + 400px);
      }
    }
  }

  &--undefined {
    min-height: 70vh;

    @include maxq(desktop-2k) {
      min-height: 85vh;
    }

    @include maxq(tablet) {
      min-height: 70vh;
    }
  }

  &-left {
    width: 339px;

    @include maxq(desktop-2k) {
      width: 226px;
    }

    @include maxq(desktop-lg) {
      width: 170px;
    }
  }

  &-right {
    width: 100%;
  }

  &--project-flat {
    overflow: hidden;
  }
}
</style>
