import axios from "axios";
import { useMyComposable } from "./conf";
import { getCookie } from "../utils/storage";
import { useMainStore } from "~/store/main";

export const reqAxios = async (
  path = "",
  data: any = {}
): Promise<{ data: any; status: number }> => {
  /** Add AMOCRM UID to some requests */
  if (typeof window !== "undefined") {
    // const uid = window?.__amocrm_visitor_uid; // <- '__amocrm_visitor_uid' will be injected from admin script
    const keywords = ["saveSubmitedForm"];
    const appendAdditionalData = keywords.some((el) => path.includes(el));

    // if (uid && appendAdditionalData) {
    //   data.uid = uid;
    // }

    if (appendAdditionalData) {
      data["g-recaptcha-response"] = getCookie("grecaptcha");
    }
  }
  const nuxtApp = useNuxtApp();
  const lang = nuxtApp?.$i18n?.locale?.value;
  axios.defaults.headers.common["lang"] = lang;

  const conf = useMyComposable();

  const response = await axios(conf.apiUrl + path, {
    method: "POST",
    data,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return { data: response?.data, status: response?.status };
};

type PropsType = {
  path: string;
  data?: object | string;
  method?: "POST" | "GET";
};

type ReturnType = { data: any; status: number };

export const baseReq = async ({
  path,
  data,
  method = "POST",
}: PropsType): Promise<ReturnType> => {
  const nuxtApp = useNuxtApp();
  const lang = nuxtApp?.$i18n?.locale?.value;
  axios.defaults.headers.common["lang"] = lang;

  const conf = useMyComposable();

  const response = await axios(conf.apiUrl + path, {
    method,
    data,
  }).then((response) => {
    return response;
  });

  return { data: response?.data, status: response?.status };
};

export const getWidgetSS = async (data: string | object): Promise<any> => {
  const nuxtApp = useNuxtApp();
  const lang = nuxtApp?.$i18n?.locale?.value;

  const mainStore = useMainStore();

  const name: any = typeof data === "string" ? [data] : data;

  const conf = useMyComposable();

  const response = await useFetch(conf.apiUrl + "main/parts/getWidgets", {
    method: "POST",
    body: {
      name: name,
    },
    headers: {
      lang,
    },
  });

  name?.forEach((n: string) => {
    // @ts-ignore
    if (!mainStore.widgets?.[n] && response.data?.value?.[n]) {
      // @ts-ignore
      mainStore.widgets[n] = response.data.value[n];
    }
  });

  return response;
};

export const getTaxonomySS = async (
  taxonomy: string | object
): Promise<any> => {
  const nuxtApp = useNuxtApp();
  const lang = nuxtApp?.$i18n?.locale?.value;

  const mainStore = useMainStore();

  const conf = useMyComposable();

  const response = await useFetch(conf.apiUrl + "main/parts/getTerms", {
    method: "POST",
    body: {
      taxonomy,
    },
    headers: {
      lang,
    },
  });

  // @ts-ignore
  if (!mainStore.terms?.[taxonomy] && response.data?.value) {
    // @ts-ignore
    mainStore.terms[taxonomy] = response.data.value;
  }

  return response;
};