<script lang="ts" setup>
	/**
	 * @author Beka Chkhaidze, Luka Tchighladze, Irakli Kandelaki
	 */

	/**
	 * @description BUTTON TYPE ONE: Yellow button with a pale hover state
	 * @description BUTTON TYPE TWO: Yellow button with a pale default state
	 * @description BUTTON TYPE THREE: Naked button with a yellow filling background
	 * @description BUTTON TYPE FOUR: Naked button with a dark hover
	 * @description BUTTON TYPE FIVE: Naked button with a gray hover
	 * @description BUTTON TYPE SIX: Dark button with a gray hover
	 * @desctiption BUTTON TYPE SEVEN: Naked button with a gray hover and yellow active
	 * @desctiption BUTTON TYPE EIGHT: Gray button with a dark hover
	 * @summary General button component used in the project
	 */

/** IMPORTS */

const Video = defineAsyncComponent(() => import("./SVG/actions/Video.vue"));
const Done = defineAsyncComponent(() => import("./SVG/Done.vue"));
const Right = defineAsyncComponent(() => import("./SVG/actions/Right.vue"));
const Loading = defineAsyncComponent(() => import("./SVG/Loading.vue"));
const SmallLeft = defineAsyncComponent(() => import("./SVG/chevrons/SmallLeft.vue"));
const Left = defineAsyncComponent(() => import("./SVG/chevrons/Left.vue"));
const Cube = defineAsyncComponent(() => import("./SVG/Cube.vue"));
const Rect = defineAsyncComponent(() => import("./SVG/Rect.vue"));

/** PROPS */
withDefaults(
	defineProps<{
		btnText?: string;
		loadingText?: string;
		sendingText?: string;
		btnType?: number;
		isDisabled?: boolean;
		noTranslate?: boolean;
		isLoading?: boolean;
		isDone?: boolean;
		isBack?: boolean;
		noBorder?: boolean;
		uppercase?: boolean;
		icon?: string;
		full?: boolean;
		hasPointer?: boolean;
	}>(),
	{
		btnText: "Click",
		loadingText: "",
		sendingText: "",
		noTranslate: false,
		isDisabled: false,
		isLoading: false,
		isDone: false,
		isBack: false,
		noBorder: false,
		uppercase: false,
		btnType: 1,
		full: false,
		hasPointer: true
	}
);
</script>

<template>
  <button
	:aria-label="btnText || $t('click')"
    class="button d-flex al-center j-center ov-hidden"
    :class="[
      `button--${btnType}`,
	  { 'pointer': hasPointer },
      { 'button--no-border': noBorder },
      { 'button--disabled': isDisabled },
      { 'button--full': full },
    ]"
  >
    <Transition name="fade" mode="out-in">
      <div class="button--loading d-flex al-center j-center" v-if="isLoading">
        <ClientOnly>
          <Loading />
        </ClientOnly>
        <span class="p-16 c-black pos-rel" v-if="loadingText">{{ $t(loadingText) }}</span>
      </div>

      <div
        class="button--done d-flex al-center j-center"
        v-else-if="isDone && !isLoading"
      >
        <ClientOnly>
          <Done />
        </ClientOnly>

        <span class="p-16 c-black" :class="{ uppercase: uppercase }" v-if="sendingText">{{
          $t(sendingText?.toLowerCase())
        }}</span>
      </div>

      <div
        class="button--back d-flex al-center j-center"
        v-else-if="isBack && !isLoading"
      >
        <ClientOnly>
          <SmallLeft />
        </ClientOnly>

        <span class="p-16 c-black" :class="{ uppercase: uppercase }">{{
          $t("back")
        }}</span>
      </div>

      <span class="d-flex al-center" :class="{ 'button--has-icon': icon }" v-else>
          <Right v-if="btnType === 3" />
          <Video v-else-if="icon === 'video'" />
          <Left v-else-if="icon === 'arrow'" />
          <Cube v-else-if="icon === 'cube'" />
          <Rect v-else-if="icon === 'rect'" />
          <slot />
		  
			<span
				v-if="!isLoading && !isDone"
				class="p-16 button__content c-black pos-rel"
				:class="{ uppercase: uppercase }"
			>
				{{ noTranslate ? btnText : $t(btnText?.toLowerCase()) }}
			</span>
		</span>
	</Transition>
	</button>
</template>

<style lang="scss">
	.button {
		height: 128px;
		padding: 0 54px;
		background-color: transparent;

		&--disabled {
			background-color: $disabled !important;
			color: $black !important;
			pointer-events: none;
			&__content {
				opacity: 0.4;
			}
		}

		&--no-border {
			border: none !important;
		}

		@include hover {
			.button--back {
				svg {
					path {
						stroke: #fff;
					}
				}
			}
		}

		&--full {
			width: 100%;
		}

		@include easeInOut(350ms, all);

		span {
			// font-size: 36px;
			// line-height: 44px;
			@include easeInOut(350ms, all);
		}

		svg {
			path {
				@include easeInOut(350ms, all);
			}
		}

		@include maxq(desktop-2k) {
			height: 84px;
			padding: 0 28px;
		}

		@include maxq(desktop-lg) {
			height: 64px;
			padding: 0 32px;
		}

		&--1 {
			background-color: $mango;
			text-transform: uppercase;
			@include hover {
				background-color: $mangoLight;
			}
			&:active {
				background-color: $mangoDark;
			}
			span {
				text-transform: uppercase;
				@include weight(500);
			}
		}

		&--2 {
			background-color: $calamansi;
			height: 48px;
			padding-left: 16px;
			padding-right: 16px;
			span {
				line-height: 20px;
			}
			@include hover {
				background-color: $mangoLight;
			}
			&:active {
				background-color: $mangoDark;
			}
		}

		&--3 {
			position: relative;
			padding-left: 35px;
			padding-right: 35px;
			background-color: $white;
			height: 78px;
			border: 1px solid $black;
			span {
				@include weight(500);
				margin-left: 26px;
			}
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-color: $mango;
				@include easeOut(350ms, all);
				transform: translateX(-100%);
				z-index: 2;
			}
			span,
			svg {
				position: relative;
				z-index: 3;
			}
			@include hover {
				&:after {
					transform: translateX(0);
				}
			}
			&:active {
				&:after {
					transform: translateX(100%);
				}
			}

			@include maxq(desktop-lg) {
				height: 56px;
				padding-left: 24px;
				padding-right: 24px;

				svg {
					transform: scale(1);
				}

				span {
					margin-left: 16px;
				}
			}
		}

		&--4 {
			background-color: $white;
			border: 1px solid #e1e1e1;
			height: 56px;
			span {
				line-height: 24px;
				text-transform: uppercase;
				@include weight(500);
			}
			@include hover {
				background-color: $black;
				border: 1px solid $black;
				span {
					color: $white;
				}
			}
			&:active {
				border: 1px solid #515151;
				background-color: #515151;
			}
		}

		&--5 {
			height: 78px;
			padding: 12px 16px;

			@include maxq(desktop-2k) {
				height: 58px;
			}

			@include maxq(desktop-lg) {
				height: 48px;
			}

			span {
				color: $black;
			}
			@include hover {
				background-color: $disabled;
			}
		}

		&--6 {
			background-color: $disabled;
			height: 44px;
			padding-left: 16px;
			padding-right: 16px;
			span {
				line-height: 20px;
				color: $border-active;
			}
			@include hover {
				background-color: $gray-hover;
				span {
					color: $black;
				}
			}

			&:active {
				background-color: $border-active;

				span {
					color: $white;
				}
			}
		}

		&--7 {
			background-color: $white;
			height: 52px;
			padding-left: 67px;
			padding-right: 67px;
			border: 1px solid $silver;

			span {
				line-height: 20px;
				color: $black;
			}

			@include hover {
				background-color: #f3f3f3;
			}

			&:active {
				background-color: $mango;
				border: 1px solid $mango;
			}
		}

		&--8 {
			background-color: $cultured;
			// height: 48px;
			padding-left: 12px;
			padding-right: 12px;

			span {
				line-height: 20px;
				color: $black;
			}

			@include hover {
				background-color: $black;
				span {
					color: $white;
				}
			}

			&.active {
				background-color: $black;
				span {
					color: $white;
				}
			}
		}

		&--9 {
			background-color: $black;
			border: 1px solid $black;
			span {
				line-height: 24px;
				text-transform: uppercase;
				color: white;
				@include weight(500);
			}
			@include hover {
				background-color: $white;
				border: 1px solid #e1e1e1;

				span {
					color: $black;
				}
			}
			&:active {
				border: 1px solid #515151;
				background-color: #515151;
				span {
					color: $white;
				}
			}
		}

		&--loading,
		&--done {
			span {
				margin-left: 11px;
			}
		}

		&--back {
			svg {
				margin-right: 8px;
			}
		}

		@include hover {
			.button--back {
				svg {
					path {
						stroke: #fff;
					}
				}
			}
		}

		&--has-icon {
			.icon {
				width: 54px;
				height: 54px;

				@include maxq(desktop-2k) {
					width: 34px;
					height: 34px;
				}

				@include maxq(desktop-lg) {
					width: 24px;
					height: 24px;
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}

			.button__content {
				margin-left: 8px;
			}
		}

		&[disabled] {
			pointer-events: none;
			&.button--1,
			&.button--6 {
				background-color: $disabled;
			}
			&.button--6 {
				span {
					color: $black;
				}
			}
			&.button--1 {
				span {
					color: rgba($black, 0.4);
				}
			}
		}
		.icon--loading {
			#icon-loader-outer-circle {
				animation: loading 2s linear infinite;
			}
		}

		@keyframes loading {
			from {
				transform: rotate(0);
			}

			to {
				transform: rotate(360deg);
			}
		}
	}
</style>
