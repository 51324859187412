<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */

import { useMainStore } from "@/store/main/index";
import { buildLinkObjFromMenu } from "@/composables/utils/router";
import { buildLinkObjFromWidget } from "@/composables/utils/router";
import { IndxInterface } from "@/store/main/types/StateType";

import Button from "@/components/regularComponents/UIComponents/Button.vue";
import FooterLogo from "@/components/regularComponents/UIComponents/SVG/Footer/FooterLogo.vue";
import FooterSubscribe from "@/components/smartComponents/FooterSubscribe/FooterSubscribe.vue";

const Link = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Link.vue")
);

const indx = useState<IndxInterface>("data");

const mainStore = useMainStore();

const lang = computed(() => mainStore.lang);
const companyData = computed(() => mainStore.widgets?.["footer-about-company"]);

const menu = computed(() => {
  const menus = indx.value?.menus?.filter(
    (menu) => menu.menu_type === "footer"! && !menu.configs?.includes("hide-from-menu")
  )?.sort((a, b) => a.sort - b.sort);
  return menus;
});

const socials = computed(() => {
  const values = { ...mainStore.widgets?.["socials"] };
  if (!values) return;

  delete values?.title;

  return Object.values(values);
});

watch(lang, async () => {
  await mainStore.setWidgets(["footer-about-company", "socials"]);
});

onBeforeMount(() => {
  if (!mainStore.widgets?.["footer-about-company"]) {
    mainStore.setWidgets("footer-about-company");
  }

  if (!mainStore.widgets?.["socials"]) {
    mainStore.setWidgets("socials");
  }
});
</script>

<template>
  <div class="footer__bottom">
    <div class="footer__bottom-company footer--custom-padding">
      <a href="/" aria-label="Gumbati Logo">
        <FooterLogo class="logo" />
      </a>

      <p class="about-text op-6 p-14" v-if="companyData">
        {{ companyData?.description }}
      </p>
      <Link
        :label="companyData?.link?.title"
        class="discover c-mango"
        v-if="companyData?.link"
        :link="buildLinkObjFromWidget(companyData?.link)"
      >
        <Button
          class="discover__btn"
          :uppercase="false"
          :btnText="companyData?.link?.title"
        />
      </Link>
    </div>

    <div class="footer__bottom-menu footer--custom-padding" v-if="menu?.length">
      <h2 class="parent">{{ $t("menu") }}</h2>
      <ul>
        <li class="p-16 child" v-for="(item, index) in menu" :key="index">
          <Link :label="item?.titles?.[lang]?.title" :link="buildLinkObjFromMenu(item)">{{
            item?.titles?.[lang]?.title
          }}</Link>
        </li>
      </ul>
    </div>

    <div
      class="footer__bottom-social footer--custom-padding"
      v-if="mainStore.widgets?.['socials']"
    >
      <h2 class="parent">{{ mainStore.widgets["socials"]?.title }}</h2>
      <ul>
        <li class="p-16 child" v-for="(item, index) in socials" :key="index">
          <Link :label="item?.title" :link="item">{{ item?.title }}</Link>
        </li>
      </ul>
    </div>
    <div class="footer__bottom-subscribe footer--custom-padding">
      <FooterSubscribe />
    </div>
  </div>
</template>
