/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Dynamically get window.innerWidth and window.innerHeight values.
 */

import { useMainStore } from "../../store/main/index";
import { getCurrentInstance } from "vue";

export default (): void => {
  const mainStore = useMainStore();

  const setNewSizes = (): void => {
    mainStore.windowWidth = window.innerWidth;
    mainStore.windowHeight = window.innerHeight;
    // mainStore.windowYOffset = window.pageYOffset;

    // const app = getCurrentInstance();

    // const rootEl = app?.appContext.app._container;
    // mainStore.rootEl = rootEl;
    // document?.documentElement.style.setProperty(
    //   "--100vh",
    //   mainStore?.windowHeight?.toString() + "px"
    // );
    if (mainStore.windowWidth < 1248 && mainStore.windowWidth > 983) {
      if (mainStore.windowWidth / mainStore.windowHeight <= 0.72035139092)
        mainStore.isVerticalTablet = true;
      else mainStore.isVerticalTablet = false;
    } else {
      mainStore.isVerticalTablet = false;
    }
  };
  setNewSizes();
  window?.addEventListener("resize", setNewSizes);
};
