/**
 * @author Irakli Kandelaki
 * @sumamary Building module store.
 */

import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/index";
import { baseReq, reqAxios } from "@/composables/core/func";
import { asignRoute } from "@/composables/utils/asignRoute";
import { useMyComposable } from "@/composables/core/conf";

import { useRouter } from "#imports";

/** IMPORT TYPES */
import type { Flat, ProjectInterface, StateInterface } from "./types/StateType";
import type { GettersType } from "./types/GettersType";
import type {
  ActionsInterface,
  IToggleSelectedFlatsForBooking,
} from "./types/ActionsType";
import { IndxInterface } from "@/store/main/types/StateType";

export const useBuildingStore = defineStore<
  string,
  StateInterface,
  GettersType,
  ActionsInterface
>("building", {
  state: (): StateInterface => ({
    projectList: undefined,
    selectedProject: undefined,
    selectedProjectRenderSvg: undefined,
    selectedProjectRenderImage: undefined,
    selectedProjectBlocks: undefined,
    selectedProjectFloors: undefined,
    selectedProjectTypes: undefined,
    selectedBlock: undefined,
    selectedFloor: undefined,
    selectedFlat: undefined,
    selectedProjectFlats: undefined,
    selectedFloorFlats: undefined,
    projectRes: undefined,
    currency: "usd",
    currencies: [
      {
        title: "GEL",
        value: "gel",
      },
      {
        title: "EUR",
        value: "eur",
      },
      {
        title: "USD",
        value: "usd",
      },
    ],
    projectLowestPrices: {},
    selectedFlatsForBooking: [],
    oldProjectId: null,
    checkBoxes: [],
  }),
  getters: {
    getSelectedProjectRenderSvg() {
      if (!this.selectedProject?.polygon_svg) {
        console.warn("Project render has not been found");
        return "";
      }
      return this.selectedProject.polygon_svg;
    },
    getSelectedProjectRenderImage() {
      if (!this.selectedProject?.polygonImage?.[0]?.devices?.desktop) {
        console.warn("Project render image has not been found");
        return "";
      }
      return this.selectedProject.polygonImage[0].devices.desktop;
    },
    getSelectedProjectRenderImageMobile() {
      return this?.selectedProject?.mobileImage?.[0]?.devices?.desktop || "";
    },
    getProjectNames() {
      if (!this.projectList?.length) return null;

      const projects: any = this.projectList
        ?.sort(this.projectSorter)
        .map((el) => {
          return {
            title: el?.title,
            value: el.route || el?.title,
            id: el.id,
            status: el?.taxonomy?.project_status,
          };
        });

      return projects;
    },
    getActiveProjectsNames() {
      let projects = this.getProjectNames!;
      const mainStore = useMainStore();

      const currentStatusTaxonomyId = mainStore.terms?.project_status?.find(
        (el) => el.slug === "current"
      )?.id;

      if (!currentStatusTaxonomyId) {
        console.warn("Can't find current projects taxonomy id");
        return projects;
      }

      projects = projects?.filter((el) =>
        el.status.includes(currentStatusTaxonomyId)
      );

      return projects;
    },
  },
  actions: {
    projectSorter(a: ProjectInterface, b: ProjectInterface) {
      if (a.sort === b.sort) {
        return 0;
      }
      if (a.sort === 0) {
        return 1;
      }
      if (b.sort === 0) {
        return -1;
      }
      return a.sort - b.sort;
    },
    getPrice(price: number, round = false) {
      const indx = useState<IndxInterface>("data");

      const euroRate = indx.value?.xrates?.eur || 1;
      const dollarRate = indx.value?.xrates?.usd || 1;

      let result = price;

      if (
        this.currency.toLowerCase() === "eur" &&
        indx.value?.xrates?.eur &&
        indx.value?.xrates?.usd
      ) {
        result = (price * dollarRate) / euroRate;
      }

      if (this.currency.toLowerCase() === "gel" && dollarRate) {
        result = price * dollarRate;
      }

      return round ? Math.round(result) : price;
    },
    setSelectedProjectFloorValues() {
      const mainStore = useMainStore();

      const router = useRouter();

      this.selectedProjectFloors?.forEach((floor) => {
        const routeParams = router?.currentRoute?.value?.params;
        const blockSlug = this.selectedProjectBlocks?.find(
          (x) => x.id == floor.block_id
        )?.slug;
        const locales = ["ka", "en", "ru"];
        if (
          !routeParams?.slugs?.length ||
          typeof routeParams?.slugs === "string"
        )
          return;
        const isLocaleBugged = routeParams.slugs?.some((x: string) =>
          locales.includes(x)
        );
        if (isLocaleBugged) routeParams.slugs?.shift();

        floor.route = !this.selectedProjectBlocks?.length
          ? `/${mainStore.lang}/${routeParams?.slugs?.[0]}/${routeParams?.slugs?.[1]}/floor-${floor?.floor_number}`
          : `/${mainStore.lang}/${routeParams?.slugs?.[0]}/${
              routeParams?.slugs?.[1]
            }/block-${blockSlug || this.selectedBlock?.slug?.toLowerCase()}/floor-${
              floor?.floor_number
            }`;
      });
    },
    setSelectedProjectBlockValues() {
      const router = useRouter();
      const mainStore = useMainStore();

      this.selectedProjectBlocks?.forEach((block) => {
        const routeParams = router?.currentRoute?.value?.params;

        block.route = `/${mainStore.lang}/${routeParams?.slugs?.[0]}/${
          routeParams?.slugs?.[1]
        }/block-${block?.title?.toLowerCase()}`;
        
      });
    },
    async getProjects(args) {
      const indx = useState<IndxInterface>("data");

      const forceRefetch = args?.forceRefetch || false;
      const params = args?.params || {};

      if (this.projectList?.length && !forceRefetch)
        return console.warn(`[ buildingStore ]: projectList is not empty`);

      try {
        const response = await reqAxios("development/getProjects", params);
        
        if (!response?.data?.list) {
          console.warn("Projects not found");
          return;
        }

        this.projectList = response?.data?.list?.sort(this.projectSorter);
        this.projectList?.forEach((el) => {
          if (!el.relatedSitemap) {
            console.warn(
              `there is no menu linked to the project("${el?.title}")`
            );
            return;
          }

          const matchedMenu = indx.value?.menus?.find(
            (menu) => {
              return menu.id === el.relatedSitemap
            }
          );

          if (!matchedMenu) {
            console.warn(
              `There is no menu with linked id (${el.relatedSitemap})`
            );
            return;
          }

          el.route = matchedMenu.full_url;
        });
      } catch (err) {
        console.error(err);
        this.projectList = [];
      }
    },
    async getProject() {
      const indx = useState<IndxInterface>("data");

      const router = useRouter();

      const routeParams = (router?.currentRoute?.value?.params?.slugs as string[])?.filter(
        (x: any) => x?.length !== 2
      );

      const projectMenu = indx.value?.menus?.find(
        (menu) => menu?.url_slug === routeParams[1]
      );
      const attachedProjectData = projectMenu?.relatedModules?.find(
        (relatedModule) => relatedModule?.module === "development_projects"
      );

      if (!attachedProjectData?.id) {
        console.warn("Project is not attached to the menu!");
        return;
      }

      // const res = await reqAxios("development/getComplex", {
      //   projects: {
      //     id: [attachedProjectData.id],
      //   },
      //   blocks: {
      //     project_id: attachedProjectData.id,
      //   },
      //   floors: {
      //     project_id: [attachedProjectData.id],
      //     limit: 1000,
      //   },
      // });

      const conf = useMyComposable();

      const { data: res } = await useFetch(conf.apiUrl + "development/getComplex", {
        method: "POST",
        body: {
          projects: {
            id: [attachedProjectData.id],
          },
          blocks: {
            project_id: attachedProjectData.id,
          },
          floors: {
            project_id: [attachedProjectData.id],
            limit: 1000,
          },
        },
      });

      // if (res?.status !== 200) {
      //   console.warn("Project request failed, refresh website to try again!");
      //   return;
      // } else
      if (!res?.value?.projects?.list?.[0]) {
        console.warn("No project found by this ID!");
        return;
      }

      this.selectedProject = res?.value?.projects?.list?.[0];

      /**
       * update oldProjectId
       */

      if (!this.oldProjectId) {
        this.oldProjectId = this.selectedProject?.id;
      } else if (this.oldProjectId != this.selectedProject?.id) {
        this.oldProjectId = this.selectedProject?.id;
        this.selectedFlatsForBooking = [];
      }
      this.selectedProjectRenderSvg = this.getSelectedProjectRenderSvg;
      this.selectedProjectRenderImage = this.getSelectedProjectRenderImage;
      this.selectedProjectRenderImageMobile =
        this.getSelectedProjectRenderImageMobile;
      if (!this.selectedProject?.polygon_data) {
        console.warn("No shapes have data assigned!");
        return;
      } else this.selectedProject.polygonDataToArray = [];

      for (const val of Object.values(this.selectedProject.polygon_data)) {
        this.selectedProject.polygonDataToArray.push(val);
      }
      if (res?.value?.blocks?.list?.length) {
        this.selectedProjectBlocks = res?.value?.blocks?.list;
        this.setSelectedProjectBlockValues();
      } else {
        this.selectedBlock = undefined;
        this.selectedProjectBlocks = [];
      }

      if (!res?.value?.floors?.list?.length) {
        console.warn("Floors are not attached to the project!");
        return;
      } else {
        this.selectedProjectFloors = res.value.floors.list.sort(
          (a: any, b: any) => a.floor_number - b.floor_number
        );
      }

      this.setSelectedProjectFloorValues();
    },

    async getFlatsByFloorNumber(floorPath: string) {
      if (!floorPath?.length) {
        console.warn("Invalid floor ID in the route");
        return;
      }
      const mainStore = useMainStore();

      const router = useRouter();
      const route = router.currentRoute.value;

      const routeParams = router?.currentRoute?.value?.params?.slugs;
      const floorNum = floorPath?.split("-");

      const blockSlug = route.params.slugs?.[2]?.split("-")?.[1]?.toLowerCase();

      const selectedBlockId = this.selectedProjectBlocks?.find(
        (x) => x.slug == blockSlug
      )?.id;

      this.selectedFloor = this.selectedProjectFloors?.find(
        (floorItem) =>
          floorItem?.floor_number === Number(floorNum?.[1]) && (!selectedBlockId || selectedBlockId == floorItem.block_id)
      );
      if (!this.selectedFloor) {
        console.warn("No floor has been found for this route ID!");
        return;
      }
      await mainStore.setTaxonomy("flat_type");

      const payload: any = {
        // floor_number: [this.selectedFloor?.floor_number],
        project_id: [this.selectedProject?.id],
        limit: 1000,
      };

      if (selectedBlockId) {
        payload.block_id = [selectedBlockId];
      }
      
      const flatsRes = await reqAxios("development/getFlats", payload);

      if (!flatsRes?.data?.list?.length) {
        console.warn("No matching flats found by floor.");
        return;
      }

      if (!flatsRes?.data?.filtersRanges) {
        console.warn("Ranges not found");
        return;
      }

      this.selectedFloor.filterRanges = flatsRes?.data?.filtersRanges;

      let typesRes: { data: any; status: number };
      const isFlatInner = Boolean(route?.params?.slugs?.[3]);

      if (isFlatInner) {
        typesRes = await reqAxios("development/getTypes", {
          projects: {
            id: this.selectedProject?.id,
          },
          limit: 1000,
        });

        if (!typesRes?.data?.list?.length) {
          console.warn("This project does not contain any flat type!");
          return;
        }
        this.selectedProjectTypes = typesRes?.data?.list;
      }

      const flatsWithoutRoute: Flat[] = flatsRes?.data?.list;
      asignRoute(flatsWithoutRoute, routeParams);
      const flatTypeTaxonomyIds = new Set<number>();
      flatsRes?.data?.list?.forEach((flat: Flat) => {
        if (
          !flat?.conf?.includes("sold") &&
          !flat?.conf?.includes("reserved")
        ) {
          const flatTypeTaxonomyId = flat?.taxonomy?.flat_type?.[0];
          flatTypeTaxonomyIds.add(Number(flatTypeTaxonomyId));
          const matchedTaxonomyObj = mainStore?.terms?.flat_type?.find(
            (item) => item?.id === Number(flatTypeTaxonomyId)
          );
          flat.flatTypeTitle = matchedTaxonomyObj?.title;
        }
      });

      this.selectedFloor.flatTypeTaxonomies =
        mainStore.terms?.flat_type?.filter((flatTypeTaxonomy) =>
          Array.from(flatTypeTaxonomyIds)?.includes(flatTypeTaxonomy?.id)
        );
      this.selectedFloorFlats = flatsRes?.data?.list?.filter(
        (flat: Flat) => flat?.floor_number === Number(floorNum?.[1])
      );
      this.selectedProjectFlats = flatsRes?.data?.list;
    },

    setSelectedBlock(blockPath: string) {
      const blockSlug = blockPath?.split("-");

      if (!blockSlug[1]) {
        console.warn("Invalid block route: Does not contain block-title");
        return;
      }

      this.selectedBlock = this.selectedProjectBlocks?.find(
        (block) =>
          block?.slug?.toString()?.toLowerCase() ===
          blockSlug[1]?.toLowerCase()
      );
      this.setSelectedProjectFloorValues();

      if (!this.selectedBlock) {
        console.warn("No matching block has been found");
        return;
      }

      this.selectedBlock.polygonDataToArray = [];

      if (this.selectedBlock.polygon_data) {
        for (const val of Object.values(this.selectedBlock.polygon_data)) {
          this.selectedBlock.polygonDataToArray.push(val);
        }
      }
    },

    setSelectedFlat(flatPath: string) {
      flatPath = decodeURI(flatPath);

      const floorNum = flatPath?.split("-");

      if (!floorNum[1]) {
        console.warn("Invalid flat route: Does not contain flat-id");
        return;
      }
      this.selectedFlat = this.selectedProjectFlats?.find(
        (flat) => flat.flat_number == floorNum[1]
      );

      if (!this.selectedFlat) {
        console.warn("No matching flat has been found");
        return;
      }
    },

    async getProjectLowestPrices() {
      const project_id = this.selectedProject?.id;

      if (!project_id) return console.warn("Request canceled, no project id");

      // const res = await baseReq({
      //   path: "development/getLowestPriceFlats",
      //   data: {
      //     project_id,
      //   },
      // });

      const conf = useMyComposable();

      const { data: resData } = await useFetch<any>(conf.apiUrl + "development/getLowestPriceFlats", {
        method: "POST",
        body: {
          project_id,
        },
      });
      
      this.projectLowestPrices = resData.value;
    },
    toggleSelectedFlatsForBooking(params: IToggleSelectedFlatsForBooking) {
      const { flatId } = params;

      const index = this.selectedFlatsForBooking?.findIndex(
        (el) => el.flatId == flatId
      );

      if (index == -1) {
        this.selectedFlatsForBooking?.push(params);
      } else {
        const targetEl = document.querySelector(
          `.building-module-floor__render-wrapper-in [chkbox_flat_id='${flatId}'].flat-select`
        );

        targetEl?.classList?.remove("is-selected");

        this.selectedFlatsForBooking?.splice(index, 1);
      }

      return index === -1;
    },
  },
});
