import { defineStore } from "pinia";
import { reqAxios, baseReq } from "@/composables/core/func";

import type { StateInterface } from "./types/StateType";
import type { ActionsInterface } from "./types/ActionsType";

export const useMainStore = defineStore<string, StateInterface, {}, ActionsInterface>(
  "mainStore",
  {
    state: (): StateInterface => ({
      rootEl: undefined,
      lang: "en",
      windowHeight: 0,
      windowWidth: 0,
      windowYOffset: 0,
      scrollDisabled: false,
      updateTrigger: false,
      isSingleView: false,
      isVerticalTablet: false,
      searchTermsRequested: false,
      keywords: ["news"],
      globalElRefs: {},
      headerScrolled: false,
      isScrollingUp: false,
      isFloorFlow: false,
      isBlockFlow: false,
      isFlatFlow: false,
      isProjectFlow: false,
      isFlatPageOpen: false,
      breakpoints: {
        desktop_2k: 3839,
        desktop_lg: 2559,
        desktop: 1919,
        tablet: 1247,
        mobile: 983,
        mobile_sm: 463,
      },
      widgets: {},
      terms: {},
      serverTimeStamp: null,
      autoOpenModalQueryKey: "thankyou",
      hasIgnorableQuery: false,
      isGrecaptchaLoaded: false,
      showClearCacheModal: false,
    }),

    actions: {
      getSingleDataIdFromUrl(url: string): number {
        if (!url) return 0;
        const splitUrl: string[] = url.split("-");
        const contentId: string = splitUrl?.[0];
        return Number(contentId);
      },

      async fetchWidgetTaxonomy(
        param,
        url,
        target,
        apiParamName,
        ignoreExistence
      ) {
        const tempNames = Array.isArray(param) ? param : [param];
        let names: string[] = [];

        if (ignoreExistence) {
          tempNames.forEach((name) => {
            // @ts-ignore
            if (!this?.[target]?.[name]) names.push(name);
          });
        } else {
          names = tempNames;
        }

        const res = await baseReq({
          path: `main/parts/${url}`,
          method: "POST",
          data: {
            [apiParamName]: apiParamName == "taxonomy" ? param : names,
          },
        });

        if (res.status !== 200) return;
        const { data } = res;

        let finalData;
        if (apiParamName == "name") {
          finalData = data;
        } else if (apiParamName == "taxonomy") {
          finalData = {
            [param as string]: data,
          };
        }

        this[target] = {
          ...this[target],
          ...finalData,
        };
      },
      async setWidgets(param, ignoreExistence) {
        await this.fetchWidgetTaxonomy(
          param,
          "getWidgets",
          "widgets",
          "name",
          ignoreExistence
        );
      },
      async setTaxonomy(param, ignoreExistence) {
        await this.fetchWidgetTaxonomy(
          param,
          "getTerms",
          "terms",
          "taxonomy",
          ignoreExistence
        );
      },
      addWord(words: string[]) {
        words.forEach((word) => {
          const wordkey = word.toLowerCase();
          reqAxios("main/adwrd", { wrd: wordkey });
        });
      },
    },
  }
);
