<script lang="ts" setup>
/**
 * @author Beka Chkhaidze
 *
 * using non-read-only computed ref, we can omit usage of @input event listener
 * and watch(props.modelValue).
 * let vue's reactivity handle this itself.
 */

import { ref, computed, unref } from "vue";
import type { Ref } from "vue";

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    label?: string;
    required?: boolean;
    type?: "text" | "email" | "password" | "tel";
    errorText?: string | Ref<string>;
    additionalText?: string;
    modelValue?: string | null;
    clear?: number;
    inputDisabled?: boolean;
    regex?: RegExp | null;
    noTranslate?: boolean;
  }>(),
  {
    placeholder: "",
    required: false,
    type: "text",
    value: null,
    inputDisabled: false,
    noTranslate: false,
  }
);

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const inputRef = ref<HTMLInputElement | null>(null);
const isFocused = ref<boolean>(false);
const isTypeToggled = ref<boolean>(false);

const inputModel = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const _tr = (str: string | Ref<string>) => {
  const strToLowerCase =
    typeof str === "string" ? str?.toLowerCase() : str?.value?.toLowerCase();
  return props.noTranslate ? unref(strToLowerCase) : (strToLowerCase ? t(unref(strToLowerCase)) : "");
};

const onFocusToggle = (value: boolean) => {
  isFocused.value = value;
};

const onClick = () => {
  inputRef.value?.focus();
};

const onKeyDown = (e: KeyboardEvent) => {
  if (!props.regex) return;
  const isCtrlA = e.ctrlKey && e.key === "a";
  if (!props.regex.test(e.key) && !["Tab", "Backspace"].includes(e.key) && !isCtrlA) {
    e.preventDefault();
  }
};
</script>

<template>
  <div
    :class="[
      'custom-input',
      { 'has-error': errorText },
      { 'has-slot': !!$slots?.main },
      { 'has-left-slot': $slots.left },
      { 'has-right-slot': $slots.right },
      { 'has-label': !!label },
      { 'has-value': !!modelValue },
      { 'has-password-field': type == 'password' },
    ]"
  >
    <div
      :class="['custom-input__content d-flex pos-rel', { 'is-focused': isFocused }]"
      @click="onClick"
    >
      <div class="custom-input__left-slot pos-rel" v-if="$slots.left">
        <slot name="left" />
      </div>

      <div class="custom-input__input w-full">
        <label class="custom-input__input-label" v-if="label">
          <span v-html="_tr(label)"></span>
          <sup v-if="required" class="custom-input__input-label--required">*</sup>
        </label>

        <input
          v-model="inputModel"
          ref="inputRef"
          class="custom-input__value w-full"
          :placeholder="_tr(placeholder)"
          :type="isTypeToggled ? 'text' : type"
          :disabled="inputDisabled"
          @keydown="onKeyDown"
          @focusin="onFocusToggle(true)"
          @focusout="onFocusToggle(false)"
        />

        <Transition name="fade">
          <button
            v-if="inputModel?.length && type == 'password'"
            class="custom-input__toggle pointer"
            @click="isTypeToggled = !isTypeToggled"
          >
            {{ isTypeToggled ? _tr("Hide") : _tr("Show") }}
          </button>
        </Transition>

        <div class="custom-input__default-slot" v-if="$slots.main">
          <slot name="main" />
        </div>
      </div>

      <div v-if="$slots.right" class="custom-input__right-slot d-flex al-center">
        <slot name="right" />
      </div>
    </div>

    <Transition name="fade">
      <div v-if="errorText" class="custom-input__additional is-error">
        {{ _tr(errorText) }}
      </div>
    </Transition>

    <Transition name="fade">
      <div v-if="additionalText" class="custom-input__additional">
        {{ _tr(additionalText) }}
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
.custom-input {
  --label-translateY: -50%;
  --label-fsz: 32px;
  --label-opacity: 0.6;
  --clr-outline: #{$silver};
  --clr-label: #000;
  --padding-right: 31px;
  --label-top: 50%;
  --max-height: 105px;
  --padding-content: 46px 31px 25px 31px;

  @include maxq(desktop-2k) {
    --padding-content: 34px 21px;
    --max-height: 88px;
    --padding-right: 21px;
    --label-fsz: 21px;
  }

  @include maxq(desktop-lg) {
    --max-height: 66px;
    --padding-right: 16px;
    --label-fsz: 16px;
  }

  &.has {
    &-value {
      --label-translateY: -100%;
      --label-fsz: 24px;
      --label-top: 13px;
      --label-translateY: unset;

      @include maxq(desktop-2k) {
        --label-fsz: 18px;
        // --label-top: 5px;
        --label-top: 10px;
      }

      @include maxq(desktop-lg) {
        --label-fsz: 13px;
      }
    }

    &-error {
      // --clr-outline: #{$border-error};
      --clr-label: #{$border-error};
      --label-opacity: 1;

      .custom-input__content {
        outline: 1px solid $border-error;
      }

      @include hover {
        .custom-input__content {
          outline: 1px solid $border-error-transp;
        }

        .custom-input__input-label {
          color: $border-error-transp;
        }
      }

      .custom-input__content.is-focused {
        outline: 2px solid $border-error;
      }
    }

    &-password-field {
      --padding-right: 70px;
    }

    &-label {
      .custom-input__value {
        position: relative;
        bottom: -10px;

        @include maxq(desktop-2k) {
          bottom: -17px;
        }

        @include maxq(desktop-lg) {
          bottom: -12px;
        }
      }
    }
  }

  &__content {
    position: relative;
    background-color: #fff;
    cursor: text;
    min-height: var(--max-height);
    outline: 1px solid var(--clr-outline);
    padding-right: var(--padding-right);
    padding: var(--padding-content);

    // padding: 12px 16px 14px 16px;

    // @include maxq(desktop-2k) {
    //   padding: 34px 21px;
    // }

    @include maxq(desktop-lg) {
      padding: 12px 16px 14px 16px;
    }

    @include easeIn(350ms, outline);

    @include hover {
      --clr-outline: #{$border-hover};
      --label-opacity: 1;
    }

    &.is-focused {
      outline: 2px solid $border-active;
      --label-translateY: 0;
      --label-fsz: 24px;
      --label-opacity: 1;
      --label-top: 13px;

      @include maxq(desktop-2k) {
        --label-fsz: 18px;
        // --label-top: 5px;
        --label-top: 10px;
      }

      @include maxq(desktop-lg) {
        --label-fsz: 12px;
        --label-top: 10px;
        --label-fsz: 13px;
        // line-height: 20px;
      }
    }
  }

  &__input {
    // position: relative;
    display: flex;

    &-label {
      line-height: 40px;
      // line-height: 20px;
      font-size: var(--label-fsz);
      display: flex;
      color: var(--clr-label);
      opacity: var(--label-opacity);
      position: absolute;
      top: var(--label-top);
      transform: translateY(var(--label-translateY));
      @include easeInOut(350ms, all);

      @include maxq(desktop-lg) {
        line-height: 20px;
      }

      &--required {
        margin-left: 5px;
      }
    }
  }

  &__value {
    font-size: 32px;
    line-height: 40px;
    &::placeholder {
      color: red;
    }

    @include maxq(desktop-2k) {
      font-size: 21px;
      line-height: 20px;
    }

    @include maxq(desktop-lg) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__additional {
    margin-top: 21px;
    margin-left: 31px;
    font-size: 28px;
    line-height: 38px;

    @include maxq(desktop-2k) {
      margin-top: 18px;
      margin-left: 26px;
      font-size: 21px;
      line-height: 28px;
    }

    @include maxq(desktop-lg) {
      margin-top: 8px;
      margin-left: 16px;
      font-size: 14px;
      line-height: 17px;
    }

    &.is-error {
      color: $border-error;
    }
  }

  &__toggle {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }

  &__right-slot {
    .icon {
      @include easeIn(350ms, transform);
    }
  }

  input::placeholder {
    color: #000;
    opacity: var(--label-opacity);
  }
}
</style>
