import { MenuInterface } from "~/store/main/types/StateType";

export const clearCache = () => {
  const selectedMenuState = useState<MenuInterface | undefined>("selectedMenu");
  const keywordsState = useState<string[]>("keywords");

  const url = useRequestURL();
  const params = url?.pathname.split("/").filter((p) => p);

  let singleview: null | number = null;

  const getSingleDataIdFromUrl = (url: string): number | null => {
    if (!url) return null;
    const splitUrl: string[] = url.split("-");
    const contentId: string = splitUrl?.[0];
    return Number(contentId);
  };

  params.forEach((param, i) => {
    if (!param) {
      return;
    } else if (keywordsState.value?.includes(param)) {
      singleview = getSingleDataIdFromUrl(params[i + 1]);
    }
  });
  if (selectedMenuState.value) {

    const menuId = selectedMenuState?.value?.id;
    ["ka", "en", "ru"].forEach((lang) => {
      $fetch(`/api/getContentDataL/${[menuId, lang, singleview]}`, {
        method: "POST",
        // @ts-ignore
        headers: {
          lang,
          clearCache: true,
        },
      });
    });
  }
};

export const clearAllCache = async (): Promise<void> => {
  ["ka", "en", "ru"].forEach((lang) => {
    $fetch(`/api/${lang}`, {
      method: "POST",
      // @ts-ignore
      headers: {
        clearCache: true,
      },
    });

    $fetch(`/api/getIndxL/${lang}`, {
      method: "POST",
      // @ts-ignore
      headers: {
        clearCache: true,
      },
    });

    // $fetch(`/api/getContentDataL${lang}`, {
    //   method: "POST",
    //   // @ts-ignore
    //   headers: {
    //     clearCache: true,
    //   },
    // });
  });

  $fetch("/api/sitemap", {
    method: "POST",
    // @ts-ignore
    headers: {
      clearCache: true,
    },
  });

  $fetch("/api/redirects", {
    method: "POST",
    // @ts-ignore
    headers: {
      clearCache: true,
    },
  });

  $fetch("/api/getThirdPartyScripts", {
    method: "POST",
    // @ts-ignore
    headers: {
      clearCache: true,
    },
  });
};
