/**
 * @author Beka Chkhaidze
 * @sumamary use this store for booking call modals/forms
 */

import { defineStore } from "pinia";
import { format } from "date-fns";

import type { StateInterface } from "./types/StateType";
import type { Getters } from "./types/GettersType";
import type { Actions } from "./types/ActionsType";

//                                                                  @ts-ignore
export const useBookingStore = defineStore<string, StateInterface, Getters, Actions>(
  "booking",
  {
    state: (): StateInterface => ({
      // plus 1 day
      // date: new Date(Date.now() + 86400000),
      countryCodes: null,
      timezones: null,
      date: new Date(Date.now()),
      time: null,
      dial: null,
      timezone: null,
      email: null,
      name: null,
    }),
    getters: {
      getFormatedDate: (state) => {
        const formated = format(state.date, "d MMMM, EEEE");
        const [month, dayName] = formated.split(",");
        const [date, monthName] = month.split(" ");
        const { t } = useI18n();

        return `${date} ${t(`calendar-${monthName?.toLowerCase()}`)}, ${t(
          dayName?.toLowerCase()?.trimStart()
        )}`;
      },
      getFormatForForm: (state) => {
        return format(state.date, "yyyy-MM-dd");
      },
    },
    actions: {
      async getCountryCodes() {
        const countryData = await $fetch("/api/getCountryCodes");
        this.dial = countryData?.countryCodes?.[0]?.iso2;
        this.countryCodes = countryData?.countryCodes;
        this.timezones = countryData?.timezones;
        this.timezone = this.timezones?.[0];
      },
    },
  }
);
