<script setup lang="ts">
/**
 * @author Ia Gugunava
 */

import { Validator, resetState } from "./ts/validate";

import axios from "axios";

import { useMyComposable } from "@/composables/core/conf";
import { sendEvent } from "@/composables/utils/sendEvent";

import Input from "@/components/regularComponents/FormComponents/Input.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import type { State } from "./ts/state.interface";

const conf = useMyComposable();

const state: State = reactive({
  input: {
    email: null,
  },
  errorTexts: {
    email: "",
  },
  hasError: {
    email: false,
  },
  isFormValid: false,
  doValidation: true,
  componentKey: 0,
});

const validator = new Validator();

const isClicked = ref(false);
const isSuccess = ref(false);
const responseTitle = ref("error");
const loading = ref(false);

const validate = () => {
  state.isFormValid = false;

  type REGEXESKeys = keyof typeof state.input;
  const keys = Object.keys(state.input) as Array<REGEXESKeys>;

  for (let key of keys) {
    if (state.input[key] != null) {
      const [isValid] = validator.validate({
        type: key,
        value: state.input[key]!,
      });
      state.hasError[key] = !isValid;
      if (state.hasError.email) {
        state.errorTexts.email = "wrong email";
      } else {
        state.errorTexts.email = "";
      }
      if (state.input.email === "") {
        state.errorTexts.email = "";
      }
    }
  }

  const isValid = (key: REGEXESKeys) => {
    return Boolean(state.input[key]?.length) && !state.hasError[key];
  };

  state.isFormValid = isValid("email");
};

const onSubmit = async () => {
  validate();

  const body = {
    action: "subscribe",
    email: state.input.email,
    formType: "subscribeForm",
  };

  const url = conf.apiUrl;
  const response = await axios.post(`${url}main/saveSubmitedForm`, body);

  if (response?.status === 200 && state.isFormValid) {
    isSuccess.value = true;
    responseTitle.value = "success";

    sendEvent("form-submission", { formType: "Subscribe to news" });
  }

  if (response?.data?.sendInfo?.original?.status === "already_subscribed") {
    isSuccess.value = false;
    state.errorTexts.email = "already subscribed";
    setTimeout(() => {
      state.errorTexts.email = "";
    }, 5000);
  }

  if (state.isFormValid) {
    isClicked.value = true;
    loading.value = true;
    setTimeout(() => {
      loading.value = false;
    }, 500);
    resetState(state);
  }

  setTimeout(() => {
    isClicked.value = false;
    isSuccess.value = false;
  }, 5000);
};

watch(
  () => state.input,
  () => {
    if (state.doValidation) {
      validate();
    }
  },
  { deep: true }
);
</script>

<template>
  <div class="footer-subscribe">
    <h3 class="footer-subscribe__parent hl-3-bold">
      {{ $t("subscribe header") }}
    </h3>
    <p class="footer-subscribe__text p-14">
      {{ $t("how do you want to receive information with new offers?") }}
    </p>
    <div class="footer-subscribe__form">
      <Input
        class="footer-subscribe__form__input"
        placeholder="e-mail address"
        type="email"
        :errorText="state.errorTexts.email"
        v-model="state.input.email"
        @keypress.enter="onSubmit"
      />
      <Button
        class="footer-subscribe__form__button"
        sendingText="subscribed"
        :isLoading="loading"
        :isDisabled="!state.isFormValid && !isClicked"
        :btnType="1"
        :isDone="isSuccess"
        btnText="subscribe"
        @click="onSubmit"
      />
    </div>
    <Transition name="fade" mode="out-in">
      <p
        class="footer-subscribe--response p-14"
        v-if="isClicked"
        :class="[{ red: !isSuccess }]"
      >
        {{ isSuccess ? $t("you have successfully subscribed to the newsletter") : "" }}
      </p>
    </Transition>
    <p class="unsubscribe-text">
      {{ $t("you can unsubscribe any time if you change your mind") }}
    </p>
  </div>
</template>

<style lang="scss">
.footer-subscribe {
  &__parent {
    // @include weight(700);
    // line-height: 48px;
    // font-size: 39px;
    margin-bottom: 16px;

    @include maxq(desktop-2k) {
      // font-size: 26px;
      // line-height: 32px;
      margin-bottom: 12px;
    }

    @include maxq(desktop-lg) {
      // font-size: 20px;
      // line-height: 24px;
      margin-bottom: 8px;
    }
  }

  &__text {
    // @include weight(400);
    // line-height: 43px;
    // font-size: 27px;
    margin-bottom: 34px;
    opacity: 0.6;

    @include maxq(desktop-2k) {
      // font-size: 18px;
      // line-height: 28px;
      margin-bottom: 22px;
    }

    @include maxq(desktop-lg) {
      // font-size: 14px;
      // line-height: 22px;
      margin-bottom: 16px;
    }
  }

  &__form {
    &__input {
      width: 67.48%;
      margin-bottom: 82px;

      .custom-input {
        color: #fff;
        &__content {
          background-color: transparent;
          --clr-outline: #535353;
          outline: 1px solid var(--clr-outline);
          padding-top: 31px;
          padding-bottom: 31px;
          padding-left: 20px;
          @include maxq(desktop-2k) {
            padding-top: 23px;
            padding-bottom: 23px;
          }
        }
        &__value {
          font-size: 22px;
          @include maxq(desktop-lg) {
            font-size: 15px;
          }
          &::placeholder {
            color: #fff !important;
            opacity: 0.6;
            font-size: 22px;
            @include maxq(desktop-lg) {
              font-size: 15px;
            }
          }
        }
        // input::placeholder {
        // 	color: #fff;
        // 	opacity: 0.6;
        // 	font-size: 22px;
        // 	@include maxq(desktop-lg) {
        // 		font-size: 15px;
        // 	}
        // }

        // &.has-error{
        //     --clr-outline: #{$border-error};
        // }
      }

      .has-error {
        &__content {
          --clr-outline: #{$border-error};
        }
      }

      .custom-input.is-focused {
        outline: 1px solid #535353;
      }

      @include maxq(desktop-2k) {
        width: 100%;
        margin-bottom: 24px;
      }

      @include maxq(desktop-lg) {
        margin-bottom: 16px;
      }
      @include maxq(tablet) {
        margin-bottom: 0;
      }
    }

    &__button {
      width: 67.48%;
      height: 74px;
      margin-bottom: 76px;
      span {
        font-size: 22px;
        line-height: 24px;
        @include maxq(desktop-lg) {
          font-size: 15px;
        }
      }
      @include maxq(desktop-2k) {
        width: 100%;
        margin-bottom: 24px;
      }

      @include maxq(desktop-lg) {
        margin-bottom: 16px;
        height: 56px;
      }

      @include maxq(tablet) {
        margin-bottom: 0;
        max-width: 144px;
        height: 64px;
      }

      @include maxq(mobile) {
        max-width: 100%;
      }
    }

    @include maxq(tablet) {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
    }

    @include maxq(mobile) {
      flex-direction: column;
    }
  }
  &--response {
    @include weight(400);
    line-height: 24px;
    font-size: 15px;
    opacity: 0.6;
    color: $lime;
    margin-bottom: 12px;

    @include maxq(desktop-lg) {
      line-height: 20px;
      font-size: 12px;
    }

    @include maxq(tablet) {
      margin-bottom: 8px;
    }

    @include maxq(mobile) {
      margin-bottom: 0;
    }
  }
  .red {
    color: $red;
  }

  .unsubscribe-text {
    @include weight(400);
    line-height: 24px;
    font-size: 15px;
    opacity: 0.6;

    @include maxq(desktop-lg) {
      line-height: 20px;
      font-size: 12px;
    }
  }

  input {
    color: #fff;
  }

  input::placeholder {
    color: #fff;
  }
}
</style>
