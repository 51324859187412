import {
  getLocale,
  setLocale,
  getLocaleCodes,
  getRouteBaseName,
  localePath,
  localeRoute,
  switchLocalePath,
  localeHead,
  DefaultPrefixable,
  DefaultSwitchLocalePathIntercepter,
  getComposer,
  useSwitchLocalePath
} from "vue-i18n-routing";
import { navigateTo, useState } from "#imports";
import { isString, isFunction, isArray, isObject } from "@intlify/shared";
import { nuxtI18nInternalOptions, nuxtI18nOptionsDefault, NUXT_I18N_MODULE_ID, isSSG } from "#build/i18n.options.mjs";
import {
  detectBrowserLanguage,
  getLocaleCookie,
  callVueI18nInterfaces,
  getVueI18nPropertyValue,
  loadLocale,
  defineGetter,
  getLocaleDomain,
  getDomainFromLocale,
  proxyNuxt,
  DefaultDetectBrowserLanguageFromResult
} from "#build/i18n.internal.mjs";
import { joinURL, isEqual } from "ufo";
export function _setLocale(i18n, locale) {
  return callVueI18nInterfaces(i18n, "setLocale", locale);
}
export function setCookieLocale(i18n, locale) {
  return callVueI18nInterfaces(i18n, "setLocaleCookie", locale);
}
export function setLocaleMessage(i18n, locale, messages) {
  return callVueI18nInterfaces(i18n, "setLocaleMessage", locale, messages);
}
export function mergeLocaleMessage(i18n, locale, messages) {
  return callVueI18nInterfaces(i18n, "mergeLocaleMessage", locale, messages);
}
function onBeforeLanguageSwitch(i18n, oldLocale, newLocale, initial, context) {
  return callVueI18nInterfaces(i18n, "onBeforeLanguageSwitch", oldLocale, newLocale, initial, context);
}
export function onLanguageSwitched(i18n, oldLocale, newLocale) {
  return callVueI18nInterfaces(i18n, "onLanguageSwitched", oldLocale, newLocale);
}
export async function finalizePendingLocaleChange(i18n) {
  return callVueI18nInterfaces(i18n, "finalizePendingLocaleChange");
}
function makeFallbackLocaleCodes(fallback, locales) {
  let fallbackLocales = [];
  if (isArray(fallback)) {
    fallbackLocales = fallback;
  } else if (isObject(fallback)) {
    const targets = [...locales, "default"];
    for (const locale of targets) {
      if (fallback[locale]) {
        fallbackLocales = [...fallbackLocales, ...fallback[locale].filter(Boolean)];
      }
    }
  } else if (isString(fallback) && locales.every((locale) => locale !== fallback)) {
    fallbackLocales.push(fallback);
  }
  return fallbackLocales;
}
export async function loadInitialMessages(context, messages, options) {
  const { defaultLocale, initialLocale, localeCodes, fallbackLocale, lazy } = options;
  const setter = (locale, message) => {
    const base = messages[locale] || {};
    messages[locale] = { ...base, ...message };
  };
  if (lazy && fallbackLocale) {
    const fallbackLocales = makeFallbackLocaleCodes(fallbackLocale, [defaultLocale, initialLocale]);
    await Promise.all(fallbackLocales.map((locale) => loadLocale(context, locale, setter)));
  }
  const locales = lazy ? [...(/* @__PURE__ */ new Set()).add(defaultLocale).add(initialLocale)] : localeCodes;
  await Promise.all(locales.map((locale) => loadLocale(context, locale, setter)));
  return messages;
}
export async function loadAndSetLocale(newLocale, context, i18n, {
  useCookie = nuxtI18nOptionsDefault.detectBrowserLanguage.useCookie,
  skipSettingLocaleOnNavigate = nuxtI18nOptionsDefault.skipSettingLocaleOnNavigate,
  differentDomains = nuxtI18nOptionsDefault.differentDomains,
  initial = false,
  lazy = false
} = {}) {
  let ret = false;
  const oldLocale = getLocale(i18n);
  __DEBUG__ && console.log("setLocale: new -> ", newLocale, " old -> ", oldLocale, " initial -> ", initial);
  if (!newLocale) {
    return [ret, oldLocale];
  }
  if (!initial && differentDomains) {
    return [ret, oldLocale];
  }
  if (oldLocale === newLocale) {
    return [ret, oldLocale];
  }
  const localeOverride = await onBeforeLanguageSwitch(i18n, oldLocale, newLocale, initial, context);
  const localeCodes = getLocaleCodes(i18n);
  if (localeOverride && localeCodes && localeCodes.includes(localeOverride)) {
    if (localeOverride === oldLocale) {
      return [ret, oldLocale];
    }
    newLocale = localeOverride;
  }
  const i18nFallbackLocales = getVueI18nPropertyValue(i18n, "fallbackLocale");
  if (lazy) {
    const setter = (locale, message) => mergeLocaleMessage(i18n, locale, message);
    if (i18nFallbackLocales) {
      const fallbackLocales = makeFallbackLocaleCodes(i18nFallbackLocales, [newLocale]);
      await Promise.all(fallbackLocales.map((locale) => loadLocale(context, locale, setter)));
    }
    await loadLocale(context, newLocale, setter);
  }
  if (skipSettingLocaleOnNavigate) {
    return [ret, oldLocale];
  }
  if (useCookie) {
    setCookieLocale(i18n, newLocale);
  }
  setLocale(i18n, newLocale);
  await onLanguageSwitched(i18n, oldLocale, newLocale);
  ret = true;
  return [ret, oldLocale];
}
export function detectLocale(route, context, routeLocaleGetter, nuxtI18nOptions, initialLocaleLoader, detectLocaleContext, normalizedLocales, localeCodes = []) {
  const { strategy, defaultLocale, differentDomains } = nuxtI18nOptions;
  const initialLocale = isFunction(initialLocaleLoader) ? initialLocaleLoader() : initialLocaleLoader;
  __DEBUG__ && console.log("detectLocale: initialLocale -", initialLocale);
  const { ssg, callType, firstAccess } = detectLocaleContext;
  __DEBUG__ && console.log("detectLocale: (ssg, callType, firstAccess) - ", ssg, callType, firstAccess);
  const { locale: browserLocale, stat, reason, from } = nuxtI18nOptions.detectBrowserLanguage ? detectBrowserLanguage(route, context, nuxtI18nOptions, nuxtI18nInternalOptions, detectLocaleContext, localeCodes, initialLocale) : DefaultDetectBrowserLanguageFromResult;
  __DEBUG__ && console.log(
    "detectLocale: detectBrowserLanguage (browserLocale, stat, reason, from) -",
    browserLocale,
    stat,
    reason,
    from
  );
  if (reason === "detect_ignore_on_ssg") {
    return initialLocale;
  }
  if ((from === "navigator_or_header" || from === "cookie" || from === "fallback") && browserLocale) {
    return browserLocale;
  }
  let finalLocale = browserLocale;
  __DEBUG__ && console.log("detectLocale: finaleLocale first (finaleLocale, strategy) -", finalLocale, strategy);
  if (!finalLocale) {
    if (differentDomains) {
      finalLocale = getLocaleDomain(normalizedLocales);
    } else if (strategy !== "no_prefix") {
      finalLocale = routeLocaleGetter(route);
    } else {
      if (!nuxtI18nOptions.detectBrowserLanguage) {
        finalLocale = initialLocale;
      }
    }
  }
  __DEBUG__ && console.log(
    "detectLocale: finaleLocale second (finaleLocale, detectBrowserLanguage) -",
    finalLocale,
    nuxtI18nOptions.detectBrowserLanguage
  );
  if (!finalLocale && nuxtI18nOptions.detectBrowserLanguage && nuxtI18nOptions.detectBrowserLanguage.useCookie) {
    finalLocale = getLocaleCookie(context, { ...nuxtI18nOptions.detectBrowserLanguage, localeCodes }) || "";
  }
  __DEBUG__ && console.log("detectLocale: finalLocale last (finalLocale, defaultLocale) -", finalLocale, defaultLocale);
  if (!finalLocale) {
    finalLocale = defaultLocale || "";
  }
  __DEBUG__ && console.log("detectLocale: finalLocale -", finalLocale);
  return finalLocale;
}
export function detectRedirect({
  route,
  context,
  targetLocale,
  routeLocaleGetter,
  nuxtI18nOptions,
  calledWithRouting = false
}) {
  const { strategy, differentDomains } = nuxtI18nOptions;
  __DEBUG__ && console.log("detectRedirect: targetLocale -> ", targetLocale);
  __DEBUG__ && console.log("detectRedirect: route -> ", route);
  __DEBUG__ && console.log("detectRedirect: calledWithRouting -> ", calledWithRouting, routeLocaleGetter(route.to));
  let redirectPath = "";
  const isStaticGenerate = isSSG && process.server;
  if (!isStaticGenerate && !differentDomains && (calledWithRouting || strategy !== "no_prefix" && strategy !== "prefix_and_default") && routeLocaleGetter(route.to) !== targetLocale) {
    const { fullPath } = route.to;
    const routePath = context.$switchLocalePath(targetLocale) || context.$localePath(fullPath, targetLocale);
    __DEBUG__ && console.log("detectRedirect: calculate routePath -> ", routePath, fullPath);
    if (isString(routePath) && routePath && !isEqual(routePath, fullPath) && !routePath.startsWith("//")) {
      redirectPath = !(route.from && route.from.fullPath === routePath) ? routePath : "";
    }
  }
  if ((differentDomains || isSSG && process.client) && routeLocaleGetter(route.to) !== targetLocale) {
    const switchLocalePath2 = useSwitchLocalePath({
      i18n: getComposer(context.$i18n),
      route: route.to,
      router: context.$router
    });
    const routePath = switchLocalePath2(targetLocale);
    __DEBUG__ && console.log("detectRedirect: calculate domain or ssg routePath -> ", routePath);
    if (isString(routePath)) {
      redirectPath = routePath;
    }
  }
  return redirectPath;
}
function isRootRedirectOptions(rootRedirect) {
  return isObject(rootRedirect) && "path" in rootRedirect && "statusCode" in rootRedirect;
}
const useRedirectState = () => useState(NUXT_I18N_MODULE_ID + ":redirect", () => "");
function _navigate(redirectPath, status) {
  return navigateTo(redirectPath, { redirectCode: status });
}
export async function navigate(args, {
  status = 301,
  rootRedirect = nuxtI18nOptionsDefault.rootRedirect,
  differentDomains = nuxtI18nOptionsDefault.differentDomains,
  skipSettingLocaleOnNavigate = nuxtI18nOptionsDefault.skipSettingLocaleOnNavigate,
  enableNavigate = false
} = {}) {
  const { i18n, locale, route } = args;
  let { redirectPath } = args;
  __DEBUG__ && console.log(
    "navigate options ",
    status,
    rootRedirect,
    differentDomains,
    skipSettingLocaleOnNavigate,
    enableNavigate
  );
  __DEBUG__ && console.log("navigate isSSG", isSSG);
  if (route.path === "/" && rootRedirect) {
    if (isString(rootRedirect)) {
      redirectPath = "/" + rootRedirect;
    } else if (isRootRedirectOptions(rootRedirect)) {
      redirectPath = "/" + rootRedirect.path;
      status = rootRedirect.statusCode;
    }
    __DEBUG__ && console.log("navigate: rootRedirect mode redirectPath -> ", redirectPath, " status -> ", status);
    return _navigate(redirectPath, status);
  }
  if (process.client && skipSettingLocaleOnNavigate) {
    i18n.__pendingLocale = locale;
    i18n.__pendingLocalePromise = new Promise((resolve) => {
      i18n.__resolvePendingLocalePromise = resolve;
    });
    if (!enableNavigate) {
      return;
    }
  }
  if (!differentDomains) {
    if (redirectPath) {
      return _navigate(redirectPath, status);
    }
  } else {
    const state = useRedirectState();
    __DEBUG__ && console.log("redirect state ->", state.value, "redirectPath -> ", redirectPath);
    if (process.client) {
      if (state.value && state.value !== redirectPath) {
        state.value = "";
        window.location.assign(redirectPath);
      }
    } else if (process.server) {
      __DEBUG__ && console.log("differentDomains servermode ", redirectPath);
      state.value = redirectPath;
    }
  }
}
export function injectNuxtHelpers(nuxt, i18n) {
  defineGetter(nuxt, "$i18n", i18n.global);
  for (const pair of [
    ["getRouteBaseName", getRouteBaseName],
    ["localePath", localePath],
    ["localeRoute", localeRoute],
    ["switchLocalePath", switchLocalePath],
    ["localeHead", localeHead]
  ]) {
    defineGetter(nuxt, "$" + pair[0], proxyNuxt(nuxt, pair[1]));
  }
}
export function extendPrefixable(differentDomains) {
  return (opts) => {
    return DefaultPrefixable(opts) && !differentDomains;
  };
}
export function extendSwitchLocalePathIntercepter(differentDomains, normalizedLocales, nuxt) {
  return (path, locale) => {
    if (differentDomains) {
      const domain = getDomainFromLocale(locale, normalizedLocales, nuxt);
      __DEBUG__ && console.log("extendSwitchLocalePathIntercepter: domain -> ", domain, " path -> ", path);
      if (domain) {
        return joinURL(domain, path);
      } else {
        return path;
      }
    } else {
      return DefaultSwitchLocalePathIntercepter(path, locale);
    }
  };
}
export function extendBaseUrl(baseUrl, options) {
  return (context) => {
    if (isFunction(baseUrl)) {
      const baseUrlResult = baseUrl(context);
      __DEBUG__ && console.log("baseUrl: using localeLoader function -", baseUrlResult);
      return baseUrlResult;
    }
    const { differentDomains, localeCodeLoader, normalizedLocales } = options;
    const localeCode = isFunction(localeCodeLoader) ? localeCodeLoader() : localeCodeLoader;
    if (differentDomains && localeCode) {
      const domain = getDomainFromLocale(localeCode, normalizedLocales, options.nuxt);
      if (domain) {
        __DEBUG__ && console.log("baseUrl: using differentDomains -", domain);
        return domain;
      }
    }
    const config = context.$config?.public?.i18n;
    if (config?.baseUrl) {
      __DEBUG__ && console.log("baseUrl: using runtimeConfig -", config.baseUrl);
      return config.baseUrl;
    }
    return baseUrl;
  };
}
