import { default as _91_46_46_46slugs_93WJNsqg8x8HMeta } from "/home/viris-xorci/projects/gumbati/pages/[...slugs].vue?macro=true";
import { default as clear_45cache8uBVlFCqzYMeta } from "/home/viris-xorci/projects/gumbati/pages/clear-cache.vue?macro=true";
import { default as errorbIhbosWKqYMeta } from "/home/viris-xorci/projects/gumbati/pages/error.vue?macro=true";
import { default as indexjK8lJbWLWPMeta } from "/home/viris-xorci/projects/gumbati/pages/index.vue?macro=true";
import { default as privacy_45policy_45testhlV4dfD2FWMeta } from "/home/viris-xorci/projects/gumbati/pages/privacy-policy-test.vue?macro=true";
export default [
  {
    name: _91_46_46_46slugs_93WJNsqg8x8HMeta?.name ?? "slugs___ka",
    path: _91_46_46_46slugs_93WJNsqg8x8HMeta?.path ?? "/ka/:slugs(.*)*",
    meta: _91_46_46_46slugs_93WJNsqg8x8HMeta || {},
    alias: _91_46_46_46slugs_93WJNsqg8x8HMeta?.alias || [],
    redirect: _91_46_46_46slugs_93WJNsqg8x8HMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93WJNsqg8x8HMeta?.name ?? "slugs___en",
    path: _91_46_46_46slugs_93WJNsqg8x8HMeta?.path ?? "/:slugs(.*)*",
    meta: _91_46_46_46slugs_93WJNsqg8x8HMeta || {},
    alias: _91_46_46_46slugs_93WJNsqg8x8HMeta?.alias || [],
    redirect: _91_46_46_46slugs_93WJNsqg8x8HMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93WJNsqg8x8HMeta?.name ?? "slugs___ru",
    path: _91_46_46_46slugs_93WJNsqg8x8HMeta?.path ?? "/ru/:slugs(.*)*",
    meta: _91_46_46_46slugs_93WJNsqg8x8HMeta || {},
    alias: _91_46_46_46slugs_93WJNsqg8x8HMeta?.alias || [],
    redirect: _91_46_46_46slugs_93WJNsqg8x8HMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: clear_45cache8uBVlFCqzYMeta?.name ?? "clear-cache___ka",
    path: clear_45cache8uBVlFCqzYMeta?.path ?? "/ka/clear-cache",
    meta: clear_45cache8uBVlFCqzYMeta || {},
    alias: clear_45cache8uBVlFCqzYMeta?.alias || [],
    redirect: clear_45cache8uBVlFCqzYMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/clear-cache.vue").then(m => m.default || m)
  },
  {
    name: clear_45cache8uBVlFCqzYMeta?.name ?? "clear-cache___en",
    path: clear_45cache8uBVlFCqzYMeta?.path ?? "/clear-cache",
    meta: clear_45cache8uBVlFCqzYMeta || {},
    alias: clear_45cache8uBVlFCqzYMeta?.alias || [],
    redirect: clear_45cache8uBVlFCqzYMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/clear-cache.vue").then(m => m.default || m)
  },
  {
    name: clear_45cache8uBVlFCqzYMeta?.name ?? "clear-cache___ru",
    path: clear_45cache8uBVlFCqzYMeta?.path ?? "/ru/clear-cache",
    meta: clear_45cache8uBVlFCqzYMeta || {},
    alias: clear_45cache8uBVlFCqzYMeta?.alias || [],
    redirect: clear_45cache8uBVlFCqzYMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/clear-cache.vue").then(m => m.default || m)
  },
  {
    name: errorbIhbosWKqYMeta?.name ?? "error___ka",
    path: errorbIhbosWKqYMeta?.path ?? "/ka/error",
    meta: errorbIhbosWKqYMeta || {},
    alias: errorbIhbosWKqYMeta?.alias || [],
    redirect: errorbIhbosWKqYMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorbIhbosWKqYMeta?.name ?? "error___en",
    path: errorbIhbosWKqYMeta?.path ?? "/error",
    meta: errorbIhbosWKqYMeta || {},
    alias: errorbIhbosWKqYMeta?.alias || [],
    redirect: errorbIhbosWKqYMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorbIhbosWKqYMeta?.name ?? "error___ru",
    path: errorbIhbosWKqYMeta?.path ?? "/ru/error",
    meta: errorbIhbosWKqYMeta || {},
    alias: errorbIhbosWKqYMeta?.alias || [],
    redirect: errorbIhbosWKqYMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexjK8lJbWLWPMeta?.name ?? "index___ka",
    path: indexjK8lJbWLWPMeta?.path ?? "/ka",
    meta: indexjK8lJbWLWPMeta || {},
    alias: indexjK8lJbWLWPMeta?.alias || [],
    redirect: indexjK8lJbWLWPMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjK8lJbWLWPMeta?.name ?? "index___en",
    path: indexjK8lJbWLWPMeta?.path ?? "/",
    meta: indexjK8lJbWLWPMeta || {},
    alias: indexjK8lJbWLWPMeta?.alias || [],
    redirect: indexjK8lJbWLWPMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjK8lJbWLWPMeta?.name ?? "index___ru",
    path: indexjK8lJbWLWPMeta?.path ?? "/ru",
    meta: indexjK8lJbWLWPMeta || {},
    alias: indexjK8lJbWLWPMeta?.alias || [],
    redirect: indexjK8lJbWLWPMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45testhlV4dfD2FWMeta?.name ?? "privacy-policy-test___ka",
    path: privacy_45policy_45testhlV4dfD2FWMeta?.path ?? "/ka/privacy-policy-test",
    meta: privacy_45policy_45testhlV4dfD2FWMeta || {},
    alias: privacy_45policy_45testhlV4dfD2FWMeta?.alias || [],
    redirect: privacy_45policy_45testhlV4dfD2FWMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/privacy-policy-test.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45testhlV4dfD2FWMeta?.name ?? "privacy-policy-test___en",
    path: privacy_45policy_45testhlV4dfD2FWMeta?.path ?? "/privacy-policy-test",
    meta: privacy_45policy_45testhlV4dfD2FWMeta || {},
    alias: privacy_45policy_45testhlV4dfD2FWMeta?.alias || [],
    redirect: privacy_45policy_45testhlV4dfD2FWMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/privacy-policy-test.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45testhlV4dfD2FWMeta?.name ?? "privacy-policy-test___ru",
    path: privacy_45policy_45testhlV4dfD2FWMeta?.path ?? "/ru/privacy-policy-test",
    meta: privacy_45policy_45testhlV4dfD2FWMeta || {},
    alias: privacy_45policy_45testhlV4dfD2FWMeta?.alias || [],
    redirect: privacy_45policy_45testhlV4dfD2FWMeta?.redirect || undefined,
    component: () => import("/home/viris-xorci/projects/gumbati/pages/privacy-policy-test.vue").then(m => m.default || m)
  }
]