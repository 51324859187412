import { IndxInterface, MenuInterface } from "@/store/main/types/StateType";
import { reqAxios } from "@/composables/core/func";
import { useMainStore } from "@/store/main";
import { useState } from "nuxt/app";
import {
  CurrentContentInterface,
  SecondaryDataInterface,
} from "types/ContentType";

export default () => {
  const currentContentStatusState = useState<string>("currentContentStatus");

  const keywordsState = useState<string[]>("keywords");
  keywordsState.value = ["news"];

  const routeParams: {
    path: string;
    params: string[];
    singleview: number | null;
    locales: Array<"ka" | "en" | "ru">;
    locale: "ka" | "en" | "ru";
  } = {
    path: "",
    params: [],
    locales: ["ka", "en", "ru"],
    locale: "en",
    singleview: null,
  };
  const getParamsFromUrl = (toRoute: string[] = []) => {
    const url = useRequestURL();

    if (!toRoute?.length) {
      const params = url?.pathname.split("/").filter((p) => p);
      const isLocaleMatched = routeParams.locales.find(
        (locale) => params?.[0] === locale
      );
      if (isLocaleMatched && params.length) {
        const matchedLocale = params?.[0] as "ka" | "en" | "ru";
        routeParams.locale = matchedLocale;
        params.shift();
      } else {
        routeParams.locale = "en";
      }
      routeParams.params = params;
    } else {
      const params = toRoute;
      const isLocaleMatched = routeParams.locales.find(
        (locale) => params?.[0] === locale
      );

      if (isLocaleMatched && params.length) {
        const matchedLocale = params?.[0] as "ka" | "en" | "ru";
        routeParams.locale = matchedLocale;
        params.shift();
      } else {
        routeParams.locale = "en";
      }

      routeParams.params = params;
    }
  };
  // getParamsFromUrl();
  const nuxtApp = useNuxtApp();
  const lang = nuxtApp?.$i18n?.locale?.value;
  //   const indxData: IndxInterface = await $fetch("/api/getIndx");
  //   dataState.value = indxData;
  const getSingleDataIdFromUrl = (url: string): number | null => {
    if (!url) return null;
    const splitUrl: string[] = url.split("-");
    const contentId: string = splitUrl?.[0];
    return Number(contentId);
  };

  const setMenuUrl = (dataState: Ref<IndxInterface>): void => {
    if (!dataState.value) return;
    const menus = dataState.value?.menus;
    if (!menus) return;
    const trueMenus = menus?.filter((menu) => !menu?.redirect_url?.length);
    menus.map((menu) => {
      if (!menu?.fullUrls?.[lang]) {
        console.warn("No lang key found in fullUrls object");
        return menu;
      }
      const trueMenu =
        !menu.redirect_url.includes("http") && menu?.redirect_url?.length
          ? trueMenus.find((item) => item?.url_slug === menu?.url_slug)
          : menu;
      if (!trueMenu) return menu;

      menu.full_url = trueMenu?.fullUrls?.[lang]?.replaceAll(/\/\//g, "/");
      trueMenu.hidden = trueMenu?.configs?.includes("hide-from-menu");
      return menu;
    });
  };

  const getCurrentContent = async (
    contentid: number
  ): Promise<{
    currentContent: CurrentContentInterface;
    selectedComponents: SecondaryDataInterface[];
  }> => {
    if (!contentid) {
      currentContentStatusState.value = "resolved";
      return {
        currentContent: undefined,
        selectedComponents: undefined,
      };
    }

    const nuxtApp = useNuxtApp();
    const lang = nuxtApp?.$i18n?.locale?.value;

    const reqParams: {
      contentid: number | undefined;
      selectedlan: "ka" | "en" | "ru";
      singleview: number | null;
    } = {
      contentid,
      selectedlan: lang,
      singleview: routeParams.singleview,
    };

    const data: CurrentContentInterface = await $fetch(
      `/api/getContentDataL/${[
        reqParams.contentid,
        reqParams.selectedlan,
        reqParams.singleview,
      ]}`,
      {
        method: "POST",
        headers: {
          lang,
        },
      }
    );

    const selectedComponents = [];
    const currentContent = data;

    let primaryContent = null;

    if (currentContent?.primary) {
      primaryContent = currentContent?.primary;
    }

    if (currentContent?.secondary) {
      for (const componentValue of Object.values(currentContent.secondary)) {
        selectedComponents.push(componentValue);
      }
    }

    currentContentStatusState.value = "resolved";

    return {
      currentContent,
      selectedComponents,
    };
  };

  const setSelectedMenu = async (
    menus: MenuInterface[],
    toRoute: string[] = []
  ): Promise<{
    selectedMenu: MenuInterface | undefined;
    currentContent: CurrentContentInterface;
    selectedComponents: SecondaryDataInterface[];
  }> => {
    if (!toRoute) {
      getParamsFromUrl([]);
    } else {
      getParamsFromUrl(toRoute);
    }

    const isProjectFlow = useState<boolean>("isProjectFlow");
    const isBlockFlow = useState<boolean>("isBlockFlow");
    const isFloorFlow = useState<boolean>("isFloorFlow");
    const isFlatFlow = useState<boolean>("isFlatFlow");

    isProjectFlow.value =
      routeParams.params?.[0] === "projects" &&
      routeParams.params?.[1]?.length > 0;

    isBlockFlow.value =
      routeParams.params?.[0] === "projects" &&
      routeParams.params?.[1]?.length > 0 &&
      routeParams.params?.[2]?.includes("block-") &&
      !routeParams.params?.[3];

    isFloorFlow.value =
      (routeParams.params?.[0] === "projects" &&
        routeParams.params?.[1]?.length > 0 &&
        routeParams.params?.[2]?.includes("floor-") &&
        !routeParams.params?.[3]) ||
      (routeParams.params?.[0] === "projects" &&
        routeParams.params?.[1]?.length > 0 &&
        routeParams.params?.[2]?.includes("block-") &&
        routeParams.params?.[3]?.includes("floor-") &&
        !routeParams.params?.[4]);

    isFlatFlow.value =
      (routeParams.params?.[0] === "projects" &&
        routeParams.params?.[1]?.length > 0 &&
        routeParams.params?.[2]?.includes("floor-") &&
        routeParams.params?.[3]?.includes("flat-")) ||
      (routeParams.params?.[0] === "projects" &&
        routeParams.params?.[1]?.length > 0 &&
        routeParams.params?.[2]?.includes("block-") &&
        routeParams.params?.[3]?.includes("floor-") &&
        routeParams?.params?.[4]?.includes("flat-"));

    if (isFloorFlow.value || isFlatFlow.value || isBlockFlow.value) {
      // selectedMenu = null;
      // const selectedMenu = null;
      const selectedComponentsState =
        useState<SecondaryDataInterface[]>("selectedComponents");
      const currentContentState =
        useState<CurrentContentInterface>("currentContent");

      return {
        selectedMenu: null,
        currentContent: currentContentState.value,
        selectedComponents: selectedComponentsState.value,
      };
    }
    const routeParamsValues = Object.values(routeParams.params).reduce(
      (accumulator: string[], currentValue: string, index: number) => {
        if (
          currentValue &&
          !keywordsState.value.includes(accumulator[index - 1])
        ) {
          accumulator[index] = currentValue;
        }
        return accumulator;
      },
      []
    );
    let paramIndex = 0;
    // let singleview: number | null = null;

    routeParams.singleview = null;
    for (const val of routeParams.params) {
      if (!val) {
        break;
      } else if (keywordsState.value?.includes(val)) {
        routeParams.singleview = getSingleDataIdFromUrl(
          routeParams.params[paramIndex + 1]
        );
        break;
      }
      paramIndex += 1;
    }
    // const isProjectFlow = route
    const selectedMenu = menus?.find((menu: MenuInterface) => {
      if (routeParamsValues.length) {
        return (
          menu.url_slug === routeParamsValues[routeParamsValues.length - 1] &&
          !menu.redirect_url.length
        );
      } else {
        return menu.set_home === 1;
      }
    });
    const selectedMenuState = useState<MenuInterface | undefined>(
      "selectedMenu"
    );

    const currentContentRes = await getCurrentContent(selectedMenu?.id);
    selectedMenuState.value = selectedMenu;

    return {
      selectedMenu,
      currentContent: currentContentRes.currentContent,
      selectedComponents: currentContentRes.selectedComponents,
    };
  };

  //   setSelectedMenu();

  return {
    setSelectedMenu,
    setMenuUrl,
    getCurrentContent,
  };
};
