import { MenuInterface } from "@/store/main/types/StateType";

import type { BaseWidgetButton } from "@/store/main/types/widgets-terms/widgets";

import { useMainStore } from "@/store/main/index";

import { Router } from ".nuxt/vue-router";

import { RouteLocationNormalizedLoaded } from "vue-router";

export const updateQuery = (key: string, value: string) => {
  const router: Router = useRouter();
  router.push({
    query: {
      ...router.currentRoute.value.query,
      [key]: value,
    },
  });
};

export const extractFromQuery = (
  key: string,
  route?: RouteLocationNormalizedLoaded
): string => {
  const router = useRouter();

  const _route = route || router?.currentRoute?.value;
  return _route?.query?.[key] as string;
};

export const buildLinkObjFromMenu = (
  link: MenuInterface,
  query: string = ""
) => {
  const mainStore = useMainStore();
  const lang = mainStore.lang;

  return {
    title: link?.titles?.[lang]?.title,
    url_target: link?.url_target,
    url: link?.redirect_url
      ? `/${lang}/` + link.redirect_url + query
      : `/${lang}/` + link?.url_slug + query,
  };
};

export const buildLinkObjFromWidget = (widget: BaseWidgetButton) => {
  const mainStore = useMainStore();
  const lang = mainStore.lang;

  const isBlank = widget?.targetblank == 1 || widget?.url?.startsWith("http");
  return {
    title: widget?.title,
    url_target: widget?.targetblank,
    url: isBlank ? widget?.url : `/${lang}/${widget?.url}`,
  };
};
