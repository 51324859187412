/**
 * @author Giorgi Torghvashvili
 */

export const useMyComposable = () => {
  const config = useRuntimeConfig();

  return {
    apiUrl: config.public.NUXT_BASE_API_URL,
    websiteUrl: config.public.NUXT_BASE_URL,
    authUrl: config.public.NUXT_AUTH_URL,
    logoutUrl: config.public.NUXT_LOGOUT_URL,
  };
};