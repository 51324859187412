<script lang="ts" setup>
/**
 * @author Luka Tchigladze
 */

import Down from "@/components/regularComponents/UIComponents/SVG/chevrons/Down.vue";

// PROPS
withDefaults(
  defineProps<{
    active: boolean;
    autoOpen?: boolean;
    hideIcon?: boolean;
    direction?: "bottom" | "top";
  }>(),
  {
    direction: "bottom",
    autoOpen: false,
  }
);
const handleScroll = (e: any) => {
  e.stopPropagation();
};
</script>

<template>
  <div
    :class="[
      'custom-dropdown d-flex al-center pos-rel pointer',
      {
        'custom-dropdown--active': active,
        'custom-dropdown--has-hover': autoOpen,
        'custom-dropdown--reversed': direction === 'top',
      },
    ]"
  >
    <div class="dropdown-icon-slot d-flex al-center pos-rel" v-if="!hideIcon">
      <slot />

      <div class="drop-icons ov-hidden">
        <div class="drop-icons-move">
          <Down />
        </div>
      </div>
    </div>

    <div class="drop-content pos-abs">
      <div
        class="drop-content-in bg-white"
        @wheel="handleScroll"
        @touchmove="handleScroll"
      >
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-dropdown {
  padding: 12px 16px;
  z-index: 4;
  @include easeOut(300ms, background-color);

  span {
    margin-right: 5px;
  }

  .drop-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  .drop-content {
    padding-top: 8px;
    top: 100%;
    right: 0;
    transform: scaleY(0);
    transform-origin: top right;
    @include easeOut(300ms, transform);

    &-in {
      min-width: 142px;
      padding: 8px;
      @include shadow;
    }
  }

  .drop-icons-move {
    svg {
      width: 46px;
      height: 34px;

      @include maxq(desktop-2k) {
        width: 34px;
        height: 24px;
      }

      @include maxq(desktop-lg) {
        width: 16px;
        height: 16px;
      }
      @include maxq(desktop-sm) {
        width: 13px;
        height: 16px;
      }
    }
  }

  &--active {
    .drop-icons-move {
      transform: rotate(-180deg);
    }

    .drop-content {
      transform: scaleY(1);
    }
  }

  &--has-hover {
    @include hover {
      .drop-icons-move {
        transform: rotate(-180deg);
      }

      .drop-content {
        transform: scaleY(1);
      }
    }
  }

  &--reversed {
    .pos-abs {
      bottom: 129px;
      @include maxq(desktop-2k) {
        bottom: 92px;
      }
      @include maxq(desktop-lg) {
        bottom: 66px;
      }
      top: unset;
    }

    .drop-content {
      transform-origin: bottom;
    }
  }
}
</style>
