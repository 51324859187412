import { IndxInterface, MenuInterface } from "@/store/main/types/StateType";
import appInitHooks from "@/composables/hooks/appInitHooks";
import {
  CurrentContentInterface,
  SecondaryDataInterface,
} from "@/types/ContentType";
import { useMainStore } from "@/store/main";
import { clearCache, clearAllCache } from "~/composables/utils/clearCache";
import { useMyComposable } from "@/composables/core/conf";

interface MetaItem {
  name: string;
  property: string;
  content: string;
}

interface MetaData {
  placeFillers: {
    meta: MetaItem[];
    title: "string";
  };
  headerObjects: {
    type: string;
    title: string;
    description: string;
    keywords: string;
    lan: string;
    canonical: string;
    canonical_alternate: {
      ka: string;
      en: string;
      ru: string;
    };
  };
}

interface CanonicalData {
  [key: string]: string;
}

interface RedirectInterface {
  [key: string]: string;
}

const redirect = async (toRoute: any) => {
  if (typeof window == "undefined") return;

  const conf = useMyComposable();

  const redirects: RedirectInterface = await $fetch("/api/redirects");
  const host = conf.websiteUrl;

  const from = host + (toRoute.fullPath !== "/" ? toRoute.fullPath : "");
  const to = redirects?.[from];

  if (to) {
    window.location.href = to;
  }
};

let isFirstLoad = true;

export default defineNuxtRouteMiddleware(async (to, from) => {
  const mainStore = useMainStore();

  mainStore.showClearCacheModal = false;

  if (Object.keys(to.query).includes("clear")) {
    clearCache();
    clearAllCache();
    mainStore.showClearCacheModal = true;

    setTimeout(() => {
      mainStore.showClearCacheModal = false;
      if (typeof window != undefined) {
        window.location.href = to.path;
      }
    }, 3000);
  }

  if (!isFirstLoad) {
    await redirect(to);
  }

  const nuxtApp = useNuxtApp();
  const lang = nuxtApp?.$i18n?.locale?.value;

  const dataState = useState<IndxInterface>("data");
  const isLoading = useState("loading");

  if (to.fullPath !== from.fullPath) {
    const isToHomePage = !to?.params?.slugs?.length;

    const isFromFloorPage =
      (from?.params?.slugs as string[])?.some((x: any) =>
        x.startsWith("floor-")
      ) &&
      !(from?.params?.slugs as string[])?.some((x: any) =>
        x.startsWith("flat-")
      );

    const isToFloorPage =
      (to?.params?.slugs as string[])?.some((x: any) =>
        x.startsWith("floor-")
      ) &&
      !(to?.params?.slugs as string[])?.some((x: any) => x.startsWith("flat-"));

    if (isFromFloorPage) {
      mainStore.isFlatPageOpen = true;
    } else {
      mainStore.isFlatPageOpen = false;
    }
    // const isProjectsListPage =
    //   to.params.slugs?.[0] === "projects" && !to.params.path2;

    const isBookingModalQueryInRoute =
      (to?.query as object)?.hasOwnProperty(mainStore.autoOpenModalQueryKey) ||
      (from?.query as object)?.hasOwnProperty(mainStore.autoOpenModalQueryKey);

    const noLoading =
      (!isToHomePage && isFromFloorPage && isToFloorPage) ||
      // isProjectsListPage ||
      isBookingModalQueryInRoute;

    if (!noLoading) {
      isLoading.value = true;

      setTimeout(() => {
        isLoading.value = false;
      }, 2000);
    }

    const currentContentStatusState = useState<string>("currentContentStatus");

    const currentContentState =
      useState<CurrentContentInterface>("currentContent");

    const indxData: IndxInterface = await $fetch(`/api/getIndxL/${lang}`, {
      method: "POST",
    });
    dataState.value = indxData;

    if (!dataState.value?.menus) return;
    const selectedComponentsState =
      useState<SecondaryDataInterface[]>("selectedComponents");

    // UNCOMMENT SETTIMEOUT ONLY IF PAGE IS ADDED IN PAGES DIRECTORY
    // setTimeout(async () => {
    const { setSelectedMenu, setMenuUrl } = appInitHooks();

    setMenuUrl(dataState);
    currentContentStatusState.value = "pending";

    const { currentContent, selectedComponents } = await setSelectedMenu(
      dataState.value.menus,
      (to?.params?.slugs as string[]) || []
    );

    currentContentState.value = currentContent;
    selectedComponentsState.value = selectedComponents;
    // }, 750);
  }

  (() => {
    if (to.path === "/about" && Object.keys(to?.query)?.length == 0) return;
    // if (to.params?.slugs?.[0] === "projects" && !to.params?.slugs?.[1]) return;

    if (typeof document !== "undefined") {
      document?.querySelector("#__nuxt")?.scrollTo({ top: 0 });
    }
  })();

  const { data: response } = await useFetch<MetaData>("/api/getMtData", {
    method: "POST",
    body: {
      url: to.fullPath,
      lang: lang,
    },
  });

  const websiteTitle = computed(
    () => dataState.value?.siteSettings?.website_title || "Gumbati"
  );

  const metaArray = [
    {
      name: "verify-v1",
      content: "mbcNALNcyZ5TtTHOKixO-4MGZ0EzQXTQhTIx8MIY3Q4",
    },

    {
      name: "google-site-verification",
      content: "mbcNALNcyZ5TtTHOKixO-4MGZ0EzQXTQhTIx8MIY3Q4",
    },

    ...response?.value?.placeFillers?.meta as MetaItem[],
  ];

  const canonicalArray: CanonicalData[] = [
    {
      rel: "canonical",
      href: response?.value?.headerObjects?.canonical as string,
    },
  ];
  if (response?.value?.headerObjects?.canonical_alternate) {
    Object.entries(response?.value?.headerObjects?.canonical_alternate)?.forEach(
      ([key, val]: [string, string]) => {
        canonicalArray.push({
          rel: "alternate",
          href: val,
          hreflang: key,
        });
      }
    );
  }

  useHead({
    title: response?.value?.placeFillers?.title + " | " + websiteTitle.value,
    link: canonicalArray,
    meta: metaArray,
    htmlAttrs: {
      lang,
    },
  });

  isFirstLoad = false;
});
