type ReturnType = [isValid: boolean, errorMessage: string | null];
export type REGEXESKeys = keyof typeof allRegexes;

interface PropType {
    value: string;
    type: REGEXESKeys;
}
const allRegexes = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
    name: /^[\w\u0080-\uFFFF]{2,}$/,
    firstName: /^[\w\u0080-\uFFFF]{2,}$/,
    lastName: /^[\w\u0080-\uFFFF]{2,}$/,
    phone: /^\d{3,}$/,
    fullName: /^[\w\u0080-\uFFFF\s]+ [\w\u0080-\uFFFF\s]+$/,
    topic: /^[\w\u0080-\uFFFF\d\s]{3,}$/,
    message: /^[\w\u0080-\uFFFF\d\s]+$/,
    employer: null,
    position: null,
    password: /^.{8,}$/,
    sex: /.+/,
    date: /.+/,
    month: /.+/,
    year: /.+/,
    nationality: /^[\w\u0080-\uFFFF]{2,}$/,
    terms: /^true$/,
    oldPassword: /^.{8,}$/,
    newPassword: /^.{8,}$/,
    repeatNewPassword: /^.{8,}$/,
    street: null,
    city: null,
    postcode: null,
    country: null,
    code: /^.{1,}$/,
};

export class Validator {
    private REGEXES = allRegexes;

    private doReturn = (
        re: RegExp,
        value: string,
        type: string
    ): ReturnType => {
        if (!value) {
            return [false, `empty ${type}`];
        }

        const isValid = re.test(value);
        let errorMessage: string | null;

        errorMessage = isValid ? null : `invalid ${type}`;

        return [isValid, errorMessage];
    };

    validate(props: PropType) {
        const { type, value } = props;
        const regexType = this.REGEXES[type];
        if (!regexType) return [false, "unknown error"];

        return this.doReturn(regexType, value, type);
    }
}

export const resetState = (state: any) => {
    state.doValidation = false;
    state.isFormValid = false;

    state.hasError.email = false;
    state.hasError.name = false;

    state.input.email = null;
    state.input.name = null;

    setTimeout(() => {
        state.doValidation = true;
    }, 500);
    state.componentKey++;
};
